import React, { useState } from "react";
import { useEffect } from "react";
import ClearDataButton from "./ClearDataButton";
import { useRef } from "react";
import {LocationMarkerIcon } from "@heroicons/react/outline";
import { getDestinationLocationAutocompleteUrl } from "api/helpers/api-constants";
import { SearchTab } from "api/types";
import { useTranslation } from "react-i18next";
import fetcher from "api/helpers/fetcher";
import { IAutocompleteTransfer } from "./AutocompleteInputForTransfer2";

export interface AutocompleteInputProps<T> {
  queryType?: SearchTab;
  localStorageToken: string;
  autocompleteValue: IAutocompleteTransfer | null;
  getUrl: (term: string) => string;
  setAutocompleteValue: React.Dispatch<React.SetStateAction<IAutocompleteTransfer | null>>;
  Icon?: React.ReactNode & React.FC<React.ComponentProps<"svg">>;
  placeHolder?: string;
  desc?: string;
  className?: string;
  autoFocus?: boolean;
  onInputDone?: () => void;
  error?: boolean;
  focused?: boolean;
  setFocused?: React.Dispatch<React.SetStateAction<boolean>>;
  setDateFocus?: React.Dispatch<React.SetStateAction<boolean>>;
}

function AutocompleteInputForTransfer({
  autoFocus = false,
  getUrl,
  autocompleteValue,
  localStorageToken,
  setAutocompleteValue,
  Icon = LocationMarkerIcon,
  placeHolder = "Airport",
  desc = "Leaving from",
  className = "nc-flex-1.5",
  onInputDone = () => {},
  error = false,
  pickupLocationId = "",
  focused = false,
  setFocused = () => {},
  setDateFocus = () => {},
}: any) {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [showPopover, setShowPopover] = useState(autoFocus);
  const [query, setQuery] = React.useState("");
  const [result, setResult] = useState([]);
  const [result2, setResult2] = useState([]);

  // const { recentSearches } = useRecentSearches<any>(localStorageToken);
  const { t } = useTranslation();

  // Add this ref to track previous pickup ID
  const previousPickupIdRef = useRef<string | null>(null);

  async function getResults(data: any) {
    try {
      // Only fetch if pickupLocationId changed
      if (previousPickupIdRef.current !== data) {
        const url = getDestinationLocationAutocompleteUrl(data);
        
        const res = await fetcher(url, { method: "GET" });
    
        if (res) {
          setResult(res); 
          setResult2(res);
          // Update ref with current ID
          previousPickupIdRef.current = data;
        }
      }
    } catch (error) {
      console.log("error", error);
      alert("error occurred");
    }
  }
  

  useEffect(() => {
    if (focused) {
      setShowPopover(true);
    }
  }, [focused]);

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
      setQuery("");
    }

    if (!showPopover) {
      if (!autocompleteValue) {
        setQuery("");
      } else {
        setQuery(autocompleteValue?.name || autocompleteValue?.label || "");
      }
    }
  }, [showPopover, autocompleteValue]);

  useEffect(() => {
    if (query) {
      const filtered = result2.filter((item: any) =>
        item.name.toLowerCase().includes(query.toLowerCase())
      );
      setResult(filtered);
    }
  }, [query]);

  useEffect(() => {
    focused && setAutocompleteValue(null);
    focused && setQuery("");
  }, [focused]);

  useEffect(() => {
    if (pickupLocationId) {
      getResults(pickupLocationId);
    }
  }, [pickupLocationId]);

  const handleInputClick = () => {
    setShowPopover(true);
    
    if (autocompleteValue || query) {
      setQuery("");
      setAutocompleteValue(null);
      
      // When clearing selection, don't trigger a refetch
      // Just show the existing results
    }
  };

  const handleSelectLocation = (item: any) => {
    setAutocompleteValue(item);
    onInputDone && onInputDone();
    setShowPopover(false);
    setFocused(false);
    setDateFocus(true);
  };

  // const renderRecentSearches = () => {
  // return (
  {
    /*<>
        <h3 className="mt-2 block px-4 text-base font-semibold text-neutral-800 dark:text-neutral-100 sm:mt-0 sm:px-8 sm:text-lg">
          {t('app.recent-searches')}
        </h3>
        <div className="mt-2">
          {recentSearches.length > 0 ? (
            recentSearches.map((recentSearch) => (
              <span
                onClick={() => handleSelectLocation(recentSearch)}
                key={recentSearch.id}
                className="flex cursor-pointer items-center space-x-2 px-2 py-2 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
              >
                <span className="block text-neutral-400">
                  <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                </span>
                <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                  {recentSearch.name || recentSearch.label}
                </span>
              </span>
            ))
          ) : (
            <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
              {t('app.no-recent-searches')}
            </span>
          )}
        </div>
          </>*/
  }
  // );
  //};

  const renderSearchValue = () => {
    return (
      <div className="absolute left-0 top-full z-40 max-h-96 w-full min-w-[280px] overflow-y-auto border bg-white  py-3 shadow-sm dark:bg-neutral-800 sm:min-w-[240px] sm:py-6">
        {result?.length && result.length > 0 ? (
          result.map((item: any, index: number) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={index}
              className="flex cursor-pointer items-center space-x-2 px-2 py-1 hover:bg-neutral-100 dark:hover:bg-neutral-700 sm:space-x-2 sm:px-4 sm:py-2"
            >
              <span className="block text-neutral-400">
                <LocationMarkerIcon className="h-4 w-4 sm:h-6 sm:w-6" />
              </span>
              <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                {item?.name || item?.label}
              </span>
            </span>
          ))
        ) : (
          <span className="px-4 py-4 text-sm text-neutral-400 sm:px-8 sm:py-5">
            {t("app.no-results")}
          </span>
        )}
      </div>
    );
  };

  const errorClassName = error ? "text-red-400" : "";

  return (
    <div className={`relative flex ${className}`} ref={containerRef}>
      <div
        onClick={handleInputClick}
        className={`[ nc-hero-field-padding ] relative flex flex-1 flex-shrink-0 cursor-pointer items-center space-x-3 text-left focus:outline-none  ${
          showPopover ? "rounded-3xl dark:bg-neutral-800" : ""
        }`}
      >
        <div className="text-neutral-300 dark:text-neutral-400">
          <Icon className="nc-icon-field" />
        </div>
        <div className="flex-grow">
          <input
            className={`xl:text-lg block w-full truncate border-none bg-transparent p-0 font-semibold placeholder-neutral-800 focus:placeholder-neutral-300 focus:outline-none focus:ring-0 dark:placeholder-neutral-200`}
            placeholder={placeHolder}
            value={query}
            autoFocus={showPopover}
            onChange={(e) => {
              setAutocompleteValue((prevAutocomplete: any) => prevAutocomplete);
              setQuery(e.currentTarget.value);
            }}
            ref={inputRef}
          />
          <span
            className={
              "-mt-1 block text-sm font-medium text-neutral-400" +
              errorClassName
            }
          >
            {placeHolder}
          </span>
          {query && showPopover && (
            <ClearDataButton
              onClick={() => {
                setQuery("");
                setAutocompleteValue(null);
              }}
            />
          )}
        </div>
      </div>
      {showPopover && (
        <div className="">
          {(query || (!query && result.length > 0)) ? (
            renderSearchValue()
          ) : (
            <span ref={containerRef} className=" invisible">
              {t("b2c-app.generals.search")}
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default AutocompleteInputForTransfer;
