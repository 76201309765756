import React, { useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import currencyFormat from 'utils/currencyFormat';

interface TableProps<T> {
  data: T[];
  columns: ColumnDef<T>[];
  sorting?: SortingState;
  onSortingChange?: (sorting: SortingState) => void;
  pagination?: PaginationState;
  onPaginationChange?: (pagination: PaginationState) => void;
  totalRecords?: number;
  onRowClick?: (row: T) => void;
  manualPagination?: boolean;
  manualSorting?: boolean;
  csvFileName?: string;
  columnFilters?: Record<string, string>;
  onColumnFilterChange?: (columnId: string, value: string) => void;
  sumColumnId?: string;
  sumRowPosition?: 'top' | 'bottom';
}

export function TableComponent<T>({
  data,
  columns,
  sorting = [],
  onSortingChange,
  pagination = {
    pageIndex: 0,
    pageSize: 10,
  },
  onPaginationChange,
  totalRecords,
  onRowClick,
  manualPagination = false,
  manualSorting = false,
  csvFileName = 'export.csv',
  columnFilters = {},
  onColumnFilterChange,
  sumColumnId,
  sumRowPosition = 'bottom',
}: TableProps<T>) {
  const [pageSize, setPageSize] = useState(pagination.pageSize);

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSize = Number(event.target.value);
    setPageSize(newSize);
    onPaginationChange?.({ ...pagination, pageSize: newSize });
  };

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      pagination,
      columnFilters: Object.entries(columnFilters).map(([id, value]) => ({
        id,
        value,
      })),
    },
    onSortingChange: (updater) => {
      if (onSortingChange) {
        onSortingChange(updater instanceof Function ? updater(sorting) : updater);
      }
    },
    onPaginationChange: (updater) => {
      if (onPaginationChange) {
        onPaginationChange(updater instanceof Function ? updater(pagination) : updater);
      }
    },
    onColumnFiltersChange: (updater) => {
      if (onColumnFilterChange) {
        const newFilters = updater instanceof Function
          ? updater(Object.entries(columnFilters).map(([id, value]) => ({ id, value })))
          : updater;
        
        newFilters.forEach(filter => {
          onColumnFilterChange(filter.id, filter.value as string);
        });
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    manualPagination,
    manualSorting,
    pageCount: manualPagination 
      ? Math.ceil((totalRecords || 0) / pagination.pageSize)
      : Math.ceil(data.length / pagination.pageSize),
  });

  const exportToCSV = () => {
    // Get headers from column definitions
    const headers = columns.map(col => {
      let header = '';
      if (typeof col.header === 'string') {
        header = col.header;
      } else if (typeof col.header === 'function') {
        const rendered = col.header({} as any);
        header = String(rendered);
      } else if (col.header) {
        header = String(col.header);
      }
      return `"${header.replace(/"/g, '""')}"`;
    });
    
    // Use ALL data, not just the current page
    const allRows = data;  // Use original data array to export everything
    const csvRows = allRows.map(row => {
      return columns.map(col => {
        const columnDef = col as ColumnDef<T> & { accessorFn?: Function; accessorKey?: string };
        let value;

        if (columnDef.accessorFn) {
          value = columnDef.accessorFn(row, 0);
        } else if (columnDef.accessorKey) {
          value = row[columnDef.accessorKey as keyof T];
        } else {
          value = '';
        }

        if (value === null || value === undefined) {
          return '""';
        }

        const isDateColumn = (columnDef.accessorKey?.toLowerCase().includes('date') || 
                            col.header?.toString().toLowerCase().includes('date'));

        let date: Date | null = null;
        
        if (value instanceof Date) {
          date = value;
        } else if (typeof value === 'number') {
          date = new Date(value);
        } else if (typeof value === 'string') {
          date = new Date(value);
        }

        if (isDateColumn) {
          if (date && !isNaN(date.getTime())) {
            return `"${date.toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit'
            })}"`;
          }
        }

        // Handle numbers with Excel-friendly formatting
        if (typeof value === 'number') {
          const isPriceColumn = col.header?.toString().toLowerCase().includes('price') || 
                              columnDef.accessorKey?.toLowerCase().includes('price');
          
          // Format with comma as decimal separator and no thousands separator
          const formattedValue = value.toFixed(2).replace('.', ',');
          return isPriceColumn ? `${formattedValue} €` : formattedValue;
        }

        // Handle strings - escape quotes and wrap in quotes
        const stringValue = String(value).replace(/"/g, '""');
        return `"${stringValue}"`;
      }).join(';');
    });

    if(sumColumnId){
      // Create a sum row with "Toplam" in the first column and the sum value in the target column
      const sumRow = columns.map((col, index) => {
        const columnDef = col as ColumnDef<T> & { accessorKey?: string; id?: string };
        const isTargetColumn = columnDef.accessorKey === sumColumnId || columnDef.id === sumColumnId;
        
        if (index === 0) {
          return '"Toplam"'; // First column shows "Toplam" label
        } else if (isTargetColumn && columnSum !== null) {
          // Format the sum value for the target column
          if (typeof columnSum === 'number') {
            return `"${columnSum.toFixed(2).replace('.', ',')} €"`;
          } else {
            return `"${columnSum}"`;
          }
        } else {
          return '""'; // Empty cells for other columns
        }
      }).join(';');
      
      csvRows.push(sumRow);
    }

    // Create CSV content with BOM for Excel
    const BOM = '\uFEFF';
    const csvContent = BOM + [headers.join(';'), ...csvRows].join('\r\n');
    
    // Create and trigger download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    
    link.setAttribute('href', url);
    link.setAttribute('download', csvFileName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const printTable = () => {
    // Create a new window for printing
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    // Generate table HTML with all data
    const tableHTML = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Print Table</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #ddd;
              padding: 8px;
              text-align: left;
            }
            th {
              background-color: #f5f5f5;
              font-weight: bold;
            }
            @media print {
              thead {
                display: table-header-group;
              }
              tr {
                page-break-inside: avoid;
              }
              @page {
                margin: 0.5in;
              }
            }
            .number-cell {
              text-align: right;
              font-variant-numeric: tabular-nums;
            }
            .sum-row {
              background-color: #f5f5f5;
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          <table>
            <thead>
              <tr>
                ${columns.map(col => {
                  let header = '';
                  if (typeof col.header === 'string') {
                    header = col.header;
                  } else if (typeof col.header === 'function') {
                    const rendered = col.header({} as any);
                    header = String(rendered);
                  } else if (col.header) {
                    header = String(col.header);
                  }
                  return `<th>${header}</th>`;
                }).join('')}
              </tr>
            </thead>
            <tbody>
              ${data.map(row => `
                <tr>
                  ${columns.map(col => {
                    const columnDef = col as ColumnDef<T> & { accessorFn?: Function; accessorKey?: string };
                    let value;

                    if (columnDef.accessorFn) {
                      value = columnDef.accessorFn(row, 0);
                    } else if (columnDef.accessorKey) {
                      value = row[columnDef.accessorKey as keyof T];
                    } else {
                      value = '';
                    }

                    if (value === null || value === undefined) {
                      return '<td></td>';
                    }

                    const isDateColumn = (columnDef.accessorKey?.toLowerCase().includes('date') || 
                                      col.header?.toString().toLowerCase().includes('date'));

                    let date: Date | null = null;
                    
                    if (value instanceof Date) {
                      date = value;
                    } else if (typeof value === 'number') {
                      date = new Date(value);
                    } else if (typeof value === 'string') {
                      date = new Date(value);
                    }

                    if (isDateColumn) {
                      if (date && !isNaN(date.getTime())) {
                        return `<td>${date.toLocaleDateString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit'
                        })}</td>`;
                      }
                    }

                    // Format all numbers with 2 decimal places
                    if (typeof value === 'number') {
                      const className = 'number-cell';
                      return `<td class="${className}">${value.toFixed(2).replace('.', ',')} €</td>`;
                    }

                    return `<td>${value}</td>`;
                  }).join('')}
                </tr>
              `).join('')}
              
              ${sumColumnId && columnSum !== null ? `
                <tr class="sum-row">
                  ${columns.map((col, index) => {
                    const columnDef = col as ColumnDef<T> & { accessorKey?: string; id?: string };
                    const isTargetColumn = columnDef.accessorKey === sumColumnId || columnDef.id === sumColumnId;
                    
                    if (index === 0) {
                      return '<td>Toplam</td>'; // First column shows "Toplam" label
                    } else if (isTargetColumn) {
                      // Format the sum value for the target column
                      if (typeof columnSum === 'number') {
                        return `<td class="number-cell">${columnSum.toFixed(2).replace('.', ',')} €</td>`;
                      } else {
                        return `<td>${columnSum}</td>`;
                      }
                    } else {
                      return '<td></td>'; // Empty cells for other columns
                    }
                  }).join('')}
                </tr>
              ` : ''}
            </tbody>
          </table>
        </body>
      </html>
    `;

    // Write the HTML to the new window and print
    printWindow.document.write(tableHTML);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
      // Close the window after printing (optional)
      // printWindow.onafterprint = () => printWindow.close();
    };
  };

  // Calculate sum for the specified column
  const calculateColumnSum = () => {
    if (!sumColumnId) return null;
    
    const column = columns.find(col => {
      const colDef = col as ColumnDef<T> & { accessorKey?: string; id?: string };
      return colDef.accessorKey === sumColumnId || colDef.id === sumColumnId;
    });
    
    if (!column) return null;
    
    const columnDef = column as ColumnDef<T> & { accessorFn?: Function; accessorKey?: string };
    let sum = 0;
    
    data.forEach(row => {
      let value;
      
      if (columnDef.accessorFn) {
        value = columnDef.accessorFn(row, 0);
      } else if (columnDef.accessorKey) {
        value = row[columnDef.accessorKey as keyof T];
      }
      
      if (typeof value === 'number') {
        sum += value;
      } else if (typeof value === 'string') {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
          sum += numValue;
        }
      }
    });
    
    return sum;
  };
  
  const columnSum = calculateColumnSum();

  const SumRow = () => {
    if (!sumColumnId || columnSum === null) return null;

    return (
      <tr className="bg-gray-100 font-semibold">
        {table.getHeaderGroups()[0].headers.map(header => {
          const isTargetColumn = 
            (header.column.columnDef as any).accessorKey === sumColumnId || 
            header.column.id === sumColumnId;
          
          return (
            <td
              key={header.id}
              style={{ width: `${header.column.getSize()}px` }}
              className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap"
            >
              {isTargetColumn ? (
                <div className="flex justify-between items-center">
                  <span>Toplam: </span>
                  <span>
                    {typeof columnSum === 'number' 
                      ? currencyFormat(columnSum)
                      : columnSum}
                  </span>
                </div>
              ) : null}
            </td>
          );
        })}
      </tr>
    );
  };

  const TopSumDisplay = () => {
    if (!sumColumnId || columnSum === null) return null;

    return (
      <div className="flex justify-end p-4 bg-gray-100 border-b border-gray-200">
        <div className="font-semibold">
          <span className="mr-2">Toplam:</span>
          <span>
            {typeof columnSum === 'number' 
              ? currencyFormat(columnSum)
              : columnSum}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full" role="region" aria-label="Data table with sorting and pagination">
      <div className="mb-4 flex justify-end space-x-2">
        <button
          onClick={printTable}
          className="px-4 py-2 border border-gray-200 text-gray-700 rounded-md hover:bg-gray-800 hover:text-white"
          aria-label="Print table"
        >
          Print
        </button>
        <button
          onClick={exportToCSV}
          className="px-4 py-2 border border-gray-200 text-gray-700 rounded-md hover:bg-gray-800 hover:text-white"
          aria-label="Export to CSV"
        >
          Export CSV
        </button>
      </div>
      <div className="overflow-x-auto shadow-sm border border-gray-200 rounded-lg">
        {sumRowPosition === 'top' && <TopSumDisplay />}
        <table 
          className="min-w-full divide-y divide-gray-200"
          role="grid"
        >
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id} role="row">
                {headerGroup.headers.map(header => (
                  <th
                    key={header.id}
                    style={{ width: `${header.column.getSize()}px` }}
                    className="px-4 py-3.5 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider bg-gray-50 hover:bg-gray-100 cursor-pointer"
                    onClick={header.column.getToggleSortingHandler()}
                    role="columnheader"
                    aria-sort={header.column.getIsSorted() ? (header.column.getIsSorted() === 'desc' ? 'descending' : 'ascending') : 'none'}
                  >
                    <div className="flex items-center space-x-1">
                      <span>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </span>
                      <span className="text-gray-400" aria-hidden="true">
                        {{
                          asc: '↑',
                          desc: '↓',
                        }[header.column.getIsSorted() as string] ?? null}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody className="bg-white divide-y divide-gray-200">
            {table.getRowModel().rows.map(row => (
              <tr
                key={row.id}
                onClick={() => onRowClick?.(row.original)}
                className="hover:bg-gray-50 transition-colors duration-150 ease-in-out cursor-pointer"
                role="row"
              >
                {row.getVisibleCells().map(cell => (
                  <td
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}px` }}
                    className="px-4 py-4 text-sm text-gray-700 whitespace-nowrap"
                    role="gridcell"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
            {sumRowPosition === 'bottom' && <SumRow />}
          </tbody>
        </table>
      </div>
      <div className="mt-6 flex items-center justify-between">
        <div>
          <span className="text-sm text-gray-600">
            Page{' '}
            <span className="font-medium">
              {table.getState().pagination.pageIndex + 1}
            </span>{' '}
            of <span className="font-medium">{table.getPageCount()}</span>
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex items-center space-x-2">
            <label htmlFor="pageSize" className="sr-only">Items per page</label>
            <select
              id="pageSize"
              value={pageSize}
              onChange={handlePageSizeChange}
              className="h-10 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
              aria-label="Number of items per page"
            >
              {[10, 20, 50, 100,1000, 5000].map(size => (
                <option key={size} value={size}>{size}</option>
              ))}
            </select>
          </div>
          <button
            className="h-10 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
            aria-label="Previous page"
          >
            Previous
          </button>
          <button
            className="h-10 px-4 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:opacity-50 disabled:cursor-not-allowed"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
            aria-label="Next page"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
