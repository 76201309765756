import React, { useState, useEffect } from 'react';
import Layout from "../layout";
import { FormProvider, useFormContext } from '../contexts/FormContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import CarRentalForm from './components/CarRentalForm';
import FlightInformationForm from './components/FlightInformationForm';
import { InvoiceForm } from './components/InvoiceForm';
import HotelBookingForm from './components/HotelBookingForm';
import GenericProductForm from './components/GenericFormComponent';
import postFetcher from 'api/helpers/postFetcher';
import moment from 'moment';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import Switch from 'components/ui/Switch/Switch';
import Label from 'components/ui/Label';
import Button from 'components/ui/Button/Button';
import TransferForm from './components/TransferForm';
import DraftPreviewDialog from './components/DraftPreviewDialog';
import { Booking } from 'api/types';
import { getInvoiceAddUrl } from 'api/helpers/api-constants';
interface HotelBooking {
  id: number;
  hotelName: string;
  checkInDate: moment.Moment;
  checkOutDate: moment.Moment;
  roomType: string;
  board: string;
  numberOfGuests: number;
  pricePerNight: number;
}

interface CreateInvoicesContentProps {
  initialData?: Booking;
  onClose?: () => void;
  standalone?: boolean;
}

const CreateInvoicesContent: React.FC<CreateInvoicesContentProps> = ({ initialData, onClose, standalone = true }) => {
  const [activeStep, setActiveStep] = useState(0);
  const { formData, updateFormData } = useFormContext();
  const [previewOpen, setPreviewOpen] = useState(false);
  
  // useEffect(() => {
  //   if (initialData) {
  //     console.log("Initial data received:", initialData);
  //     const bookingData = transformBookingData(initialData);
  //     console.log("Transformed booking data:", bookingData);
  //     updateFormData(bookingData);
  //   }
  // }, [initialData]);

  const steps = ['Invoice Details', 'Product Information', 'Passenger Information', 'Draft Summary'];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = async () => {
    const transformFormData = () => {
      const flightBookings = formData.invoiceDetails.productTypes.includes("FLIGHT")
        ? formData.flights.map((flight) => ({
            id: flight.id,
            supplier: {
              id: Number(formData.invoiceDetails.productSupplierId),
            },
            airlineFileKey: flight.airlineFileKey,
            bookingReference: flight.bookingPNR,
            airline: flight.airline,
            jupiterBooking: formData.invoiceDetails.isJupiterBooking,
            flightList: [
              {
                id: flight.id,
                flightNumber: flight.flightNumber,
                airline: flight.airline.toUpperCase(),
                classOfService: flight.classOfService,
                baggageAllowance: flight.baggage,
                departureDate: flight.departureDate,
                arrivalDate: flight.arrivalDate,
                departurePort: flight.from,
                arrivalPort: flight.to,
                stopoverPort: flight.stopover || "",
                cabinClass: flight.class.toUpperCase(),
              },
            ],
            paxList: (formData.allPassengers.FLIGHT[flight.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
              ticketnumber: passenger.ticketNumber,
              baseFare: passenger.baseFare,
              tax: passenger.tax,
              serviceFee: passenger.serviceFee,
              birthdate: passenger.birthdate,
              hiddenServiceFee: passenger.hiddenServiceFee,
            })),
          }))
        : []
    
      const hotelBookings = formData.invoiceDetails.productTypes.includes("HOTEL")
        ? formData.hotelBookings.map((hotel) => ({
            id: hotel.id,
            hotelName: hotel.hotelName,
            checkInDate: hotel.checkInDate,
            checkOutDate: hotel.checkOutDate,
            invoiceOwnerUserName: "",

            roomList: [
              {
                roomType: hotel.roomType || "string",
                board: hotel.board || "string",
               
              }
            ],
            guestList: (formData.allPassengers.HOTEL[hotel.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
              birthdate: passenger.birthdate,
            })),
          }))
        : []
    
      const rentalcarBookings = formData.invoiceDetails.productTypes.includes("RENTALCAR")
        ? formData.carRentals.map((car) => ({
            id: car.id,
            carType: car.carType,
            pickupDate: car.pickupDate,
            returnDate: car.returnDate,
            pickupLocation: car.pickupLocation,
            returnLocation: car.returnLocation,
            dailyRentPrice: car.dailyRentPrice,
            dropFee: car.dropFee,
            systemFee: car.systemFee,
            deposit: car.deposit,
            depositPaymentType: car.depositPaymentType,
            paxList: (formData.allPassengers.RENTALCAR[car.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
            })),
          }))
        : []
    
      const genericProducts = formData.invoiceDetails.productTypes.includes("GENERIC")
        ? formData.genericProducts.map((product) => ({
            id: product.id,
            productName: product.name,
            description: product.description,
            paxList: (formData.allPassengers.GENERIC[product.id] || []).map((passenger) => ({
              id: passenger.id,
              paxtype: passenger.paxType,
              gender: passenger.gender,
              firstname: passenger.name,
              surname: passenger.surname,
            })),
          }))
        : []
    
      const transferBookings = formData.invoiceDetails.productTypes.includes("TRANSFER")
        ? formData.transfers.map((transfer) => ({
            id: transfer.id,
            supplier: {
              id: Number(formData.invoiceDetails.productSupplierId),
              invoiceOwnerAgencyId: Number(formData.invoiceDetails.customerId),
              supplierName: formData.invoiceDetails.customerName,
              notes: "",
            },
            pickupLocation: transfer.pickupLocation,
            destinationLocation: transfer.destinationLocation,
            pickupDate: transfer.pickupDate.valueOf(),
            returnDate: transfer.returnDate.valueOf(),
            vehicleType: transfer.vehicleType,
            flightNumber: transfer.flightNumber,
            flightDeparturePort: transfer.flightDeparturePort,
            adtCount: transfer.adtCount,
            chdCount: transfer.chdCount,
            infCount: transfer.infCount,
            mobilePhone: transfer.mobilePhone,
            passengerList: (formData.allPassengers.TRANSFER[transfer.id] || []).map((passenger) => ({
              firstname: passenger.name,
              surname: passenger.surname
            })),
          }))
        : []
    
      return {
        id: null,
        bookingReference: formData.invoiceDetails.productRefId,
        jupiterBooking: formData.invoiceDetails.isJupiterBooking,
        flightBookings,
        hotelBookings,
        rentalcarBookings,
        transferBookings,
        genericProducts,
        payments: formData.payments.map((payment) => ({
          id: payment.id,
          paymentAccountId: payment.paymentAccount.id,
          date: payment.date,
          amount: payment.amount,
        })),
        invoiceDueDate: formData.invoiceDetails.invoiceDueDate?.format('YYYY-MM-DD') || null,
        invoiceStatus: formData.invoiceDetails.invoiceStatus,
        paid: false,
        customerUserId: Number(formData.invoiceDetails.customerId),
      }
    }
    
    
  
    const invoiceCreateForm = transformFormData()
  
    try {
      const response = await postFetcher(
        getInvoiceAddUrl(formData.invoiceDetails.customerId), 
        invoiceCreateForm
      )
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      const data = await response
      console.log(data)
      
      // Close dialog if in dialog mode
      if (onClose) {
        onClose();
      }
      
      return data
    } catch (error) {
      console.error("Error submitting form:", error)
      throw error
    }
  }
  
  const renderProductForm = () => {
    return (
      <>
        {formData.invoiceDetails.productTypes.map((product) => {
          if (product === 'FLIGHT') return <FlightInformationForm key={product} />;
          if (product === 'RENTALCAR') return <CarRentalForm key={product} />;
          if (product === 'HOTEL') return <HotelBookingForm key={product} />;
          if (product === 'GENERIC') return <GenericProductForm key={product} />;
          if (product === 'TRANSFER') return <TransferForm key={product} />;
          return null;
        })}
      </>
    );
  };
  

  return (
    <>
      {standalone && (
        <Breadcrumb 
          items={[
            {label: "Dashboard", href: "/accounting"},
            {label: "Kayıtlar", href: "/accounting/invoices"},
            {label: "Yeni Kayıt Oluştur", href: "/accounting/invoices/createnew"},
          ]} 
        />
      )}
      <div className="my-8 space-y-8">
        <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">Kayıt Detayları</h2>
            <div className="flex items-center space-x-2 mb-4">
              <Switch
                id="jupiter-booking"
                checked={formData.invoiceDetails.isJupiterBooking}
                onChange={(checked) => {
                  updateFormData({
                    invoiceDetails: {
                      ...formData.invoiceDetails,
                      isJupiterBooking: checked,
                    },
                  })
                }}
              />
              <Label>Jüpiter Rezervasyonu</Label>
            </div>
            <InvoiceForm />
          </div>
        </div>
        
        <div className="bg-white border border-gray-200 rounded-lg overflow-hidden">
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-4">Ürün Bilgileri</h2>
            {renderProductForm()}
          </div>
        </div>
        
        <div className="flex justify-end space-x-4 py-4">
          <Button 
              className=' border border-primary-6000 rounded-md font-semibold hover:bg-primary-50 transition-colors duration-200 text-primary'
            onClick={() => setPreviewOpen(true)}>
            Önizleme
          </Button>
        
        </div>
      </div>

      <DraftPreviewDialog
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        formData={formData}
        onSubmit={handleSubmit}
      />
    </>
  );
};

interface CreateInvoicesProps {
  initialData?: Booking;
  onClose?: () => void;
}

const CreateInvoices: React.FC<CreateInvoicesProps> = ({ initialData, onClose }) => {
  // If we're being used as a standalone page
  if (!initialData) {
    return (
      <Layout>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <FormProvider>
            <CreateInvoicesContent standalone={true} />
          </FormProvider>
        </LocalizationProvider>
      </Layout>
    );
  }
  
  // If we're being used in a dialog with initial data
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <FormProvider>
        <CreateInvoicesContent 
          initialData={initialData} 
          onClose={onClose} 
          standalone={false} 
        />
      </FormProvider>
    </LocalizationProvider>
  );
};

export default CreateInvoices;
