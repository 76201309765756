import React from 'react';
import { Button, TextField, Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import moment, { Moment } from 'moment';
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui';

interface QuickFilterProps {
  onFilter: (filters: {
    agencyNumber: string;
    fromDate: Moment;
    toDate: Moment;
  }) => void;
  agencyNumber: string;
}

const datePresets = [
  { 
    id: 'today',
    label: 'Bugün', 
    getValue: () => ({ 
      fromDate: moment().startOf('day'), 
      toDate: moment().endOf('day') 
    })
  },
  { 
    id: 'yesterday',
    label: 'Dün', 
    getValue: () => ({ 
      fromDate: moment().subtract(1, 'day').startOf('day'), 
      toDate: moment().subtract(1, 'day').endOf('day') 
    })
  },
  { 
    id: 'lastWeek',
    label: 'Geçen Hafta', 
    getValue: () => ({ 
      fromDate: moment().subtract(1, 'week').startOf('week'), 
      toDate: moment().subtract(1, 'week').endOf('week') 
    })
  },
  { 
    id: 'thisMonth',
    label: 'Bu Ay', 
    getValue: () => ({ 
      fromDate: moment().startOf('month'), 
      toDate: moment().endOf('month') 
    })
  },
  { 
    id: 'lastMonth',
    label: 'Geçen Ay', 
    getValue: () => ({ 
      fromDate: moment().subtract(1, 'month').startOf('month'), 
      toDate: moment().subtract(1, 'month').endOf('month') 
    })
  },
  { 
    id: 'last3Months',
    label: 'Geçen 3 Ay', 
    getValue: () => ({ 
      fromDate: moment().subtract(3, 'months').startOf('day'), 
      toDate: moment().endOf('day') 
    })
  },
  { 
    id: 'last6Months',
    label: 'Geçen 6 Ay', 
    getValue: () => ({ 
      fromDate: moment().subtract(6, 'months').startOf('day'), 
      toDate: moment().endOf('day') 
    })
  },
  { 
    id: 'thisYear',
    label: 'Bu Yıl', 
    getValue: () => ({ 
      fromDate: moment().startOf('year'), 
      toDate: moment().endOf('year') 
    })
  },
  { 
    id: 'lastYear',
    label: 'Geçen Yıl', 
    getValue: () => ({ 
      fromDate: moment().subtract(1, 'year').startOf('year'), 
      toDate: moment().subtract(1, 'year').endOf('year') 
    })
  },
] as const;

type DatePreset = typeof datePresets[number];

export const ReportQuickFilter: React.FC<QuickFilterProps> = ({
  onFilter,
  agencyNumber,
}) => {
  const [localAgencyNumber, setLocalAgencyNumber] = React.useState(agencyNumber);
  const [selectedPreset, setSelectedPreset] = React.useState<string>('today'); // Default to today
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment());

  React.useEffect(() => {
    // Apply initial filter on mount
    const preset = datePresets.find(p => p.id === selectedPreset);
    if (preset) {
      const { fromDate, toDate } = preset.getValue();
      onFilter({
        agencyNumber: localAgencyNumber,
        fromDate,
        toDate,
      });
    }
  }, []); // Only run on mount

  const handlePresetChange = (event: SelectChangeEvent) => {
    const presetId = event.target.value;
    const preset = datePresets.find(p => p.id === presetId);
    
    if (preset) {
      setSelectedPreset(preset.id);
      setSelectedDate(null); // Clear custom date when using preset
      const { fromDate, toDate } = preset.getValue();
      onFilter({
        agencyNumber: localAgencyNumber,
        fromDate,
        toDate,
      });
    }
  };

  const handleAgencyNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalAgencyNumber(event.target.value);
  };

  const handleDateChange = (date: Moment | null) => {
    if (date) {
      setSelectedDate(date);
      setSelectedPreset(''); // Clear selected preset when custom date is used
      
      // Set both fromDate and toDate to the selected date (start and end of day)
      const fromDate = date.clone().startOf('day');
      const toDate = date.clone().endOf('day');
      
      onFilter({
        agencyNumber: localAgencyNumber,
        fromDate,
        toDate,
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center gap-4">
        <TextField
          label="Acente Numarası"
          value={localAgencyNumber}
          onChange={handleAgencyNumberChange}
          size="small"
          className="w-48"
        />
        
        <DatePickermui
          date={selectedDate as moment.Moment}
          handleDateChange={handleDateChange}
          className="w-48"
        />
        
        <FormControl size="small" className="w-48">
          <InputLabel id="date-preset-select-label">Tarih Aralığı</InputLabel>
          <Select
            labelId="date-preset-select-label"
            id="date-preset-select"
            value={selectedPreset}
            label="Tarih Aralığı"
            onChange={handlePresetChange}
          >
            {datePresets.map((preset) => (
              <MenuItem key={preset.id} value={preset.id}>
                {preset.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};