import React, { FC, useState, useEffect } from 'react';
import { DateRangePicker, FocusedInputShape } from "react-dates";
import useWindowSize from "../../../hooks/useWindowResize";
import { IDateRange } from "../../../api/types";
import moment, { Moment } from "moment";
import { Close } from '@mui/icons-material';
import { Select, Button } from '@mui/material';

interface IRangeDatePicker {
  className?: string;
  dateRange: IDateRange;
  onFocusChange: FocusedInputShape | null;
  onFocusInputHandler: (focus: FocusedInputShape | null) => void;
  onDatesChangeHandler: (date: IDateRange) => void;
  onClose?: () => void;
  maxDateRange?: number; // Maximum allowed date range in days
  showPresets?: boolean; // Whether to show preset date ranges
}

const presets = [
  { label: 'Today', getValue: () => ({
    startDate: moment(),
    endDate: moment()
  })},
  { label: 'Yesterday', getValue: () => ({
    startDate: moment().subtract(1, 'day'),
    endDate: moment().subtract(1, 'day')
  })},
  { label: 'Last 7 days', getValue: () => ({
    startDate: moment().subtract(6, 'days'),
    endDate: moment()
  })},
  { label: 'Last 30 days', getValue: () => ({
    startDate: moment().subtract(29, 'days'),
    endDate: moment()
  })},
  { label: 'This month', getValue: () => ({
    startDate: moment().startOf('month'),
    endDate: moment()
  })},
  { label: 'Last month', getValue: () => ({
    startDate: moment().subtract(1, 'month').startOf('month'),
    endDate: moment().subtract(1, 'month').endOf('month')
  })},
  { label: 'Last 3 months', getValue: () => ({
    startDate: moment().subtract(3, 'months'),
    endDate: moment()
  })},
  { label: 'Year to date', getValue: () => ({
    startDate: moment().startOf('year'),
    endDate: moment()
  })}
];

export const RangeDatePicker: FC<IRangeDatePicker> = ({
  dateRange,
  onFocusInputHandler,
  onFocusChange,
  onDatesChangeHandler,
  onClose,
  className = "",
  maxDateRange,
  showPresets = false
}) => {
  const windowSize = useWindowSize();
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [rangeExceededError, setRangeExceededError] = useState<boolean>(false);
  
  // Check if current selection exceeds max range
  useEffect(() => {
    if (maxDateRange && dateRange.startDate && dateRange.endDate ) {
      const daysDiff = dateRange.endDate.diff(dateRange.startDate, 'days') + 1;
      setRangeExceededError(daysDiff > maxDateRange);
    } else {
      setRangeExceededError(false);
    }
  }, [dateRange, maxDateRange]);

  const isOutsideRange = (day: Moment) => {
    // Check if day is outside allowed date range (future or more than 10 years in past)
    const isFutureOrTooFarPast = day.isAfter(moment()) || day.isBefore(moment().subtract(10, 'year'));
    
    // If maxDateRange is set and we have a startDate selected, check if the day exceeds maxDateRange
    if (maxDateRange && dateRange.startDate && onFocusChange === 'endDate') {
      const maxDate = moment(dateRange.startDate).add(maxDateRange - 1, 'days');
      return isFutureOrTooFarPast || day.isAfter(maxDate);
    }
    
    return isFutureOrTooFarPast;
  };

  const handlePresetClick = (preset: typeof presets[0]) => {
    const range = preset.getValue();
    
    // Check if preset range exceeds maxDateRange
    if (maxDateRange && range.startDate && range.endDate) {
      const daysDiff = range.endDate.diff(range.startDate, 'days') + 1;
      if (daysDiff > maxDateRange) {
        // Adjust the range to respect maxDateRange
        range.endDate = moment(range.startDate).add(maxDateRange - 1, 'days');
      }
    }
    
    onDatesChangeHandler(range);
  };

  const handleTodayClick = () => {
    const today = moment();
    onDatesChangeHandler({
      startDate: today,
      endDate: today
    });
  };

  const renderMonthElement = ({ month, onMonthSelect, onYearSelect }: any) => (
    <div className="flex items-center justify-center gap-2 mt-xl-n3">
        
      <Select
        native
        value={month.month()}
        onChange={(e) => {
          e.stopPropagation();
          onMonthSelect(month, parseInt(e.target.value as string));
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          '& .MuiSelect-select': { py: '8px' },
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      >
        {moment.months().map((label, value) => (
          <option key={value} value={value}>{label}</option>
        ))}
      </Select>
      <Select
        native
        value={month.year()}
        onChange={(e) => {
          e.stopPropagation();
          onYearSelect(month, parseInt(e.target.value as string));
        }}
        onClick={(e) => e.stopPropagation()}
        sx={{
          '& .MuiSelect-select': { py: '8px' },
          '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
        }}
      >
        {Array.from({ length: 10 }, (_, i) => moment().year() - i).map(year => (
          <option key={year} value={year}>{year}</option>
        ))}
      </Select>
    </div>
  );

  const isDayToday = (day: Moment) => day.isSame(moment(), 'day');

  return (
    <div className={`relative ${className}`}>
      {onClose && (
        <div className="absolute right-2 top-2 flex gap-2 z-10">
          <Button
            variant="outlined"
            onClick={handleTodayClick}
            size="small"
            className="text-primary"
          >
            Today
          </Button>
          <Button
            variant="outlined"
            onClick={onClose}
            className="text-red-500"
          >
            Close
          </Button>
        </div>
      )}
      
      <div className="flex flex-col">
        {rangeExceededError && (
          <div className="text-red-500 mb-2 text-sm font-medium">
            Date range cannot exceed {maxDateRange} days
          </div>
        )}
        <div className="flex">
          <DateRangePicker
            keepFocusOnInput
            startDate={dateRange.startDate}
            startDateId="nc-hero-stay-startDateId"
            endDate={dateRange.endDate}
            endDateId="nc-hero-stay-endDateId"
            focusedInput={onFocusChange}
            onDatesChange={onDatesChangeHandler}
            onFocusChange={onFocusInputHandler}
            numberOfMonths={2}
            daySize={windowSize.width > 500 ? 56 : undefined}
            orientation="horizontal"
            showClearDates
            noBorder
            keepOpenOnDateSelect
            hideKeyboardShortcutsPanel
            anchorDirection="right"
            renderMonthElement={renderMonthElement}
            renderCalendarInfo={() => showPresets ? (
              <div className="p-4 bg-gray-50 border-l">
                <div className="grid grid-cols-8 gap-2">
                  {presets.map((preset, index) => (
                    <Button
                      variant="outlined"
                      key={index}
                      onClick={() => handlePresetClick(preset)}
                      sx={{
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                        px: 2,
                        py: 1,
                        '&:hover': { backgroundColor: 'primary.50' }
                      }}
                    >
                      {preset.label}
                    </Button>
                  ))}
                </div>
              </div>
            ) : null}
            isOutsideRange={isOutsideRange}
            isDayHighlighted={isDayToday}
            renderDayContents={(day) => (
              <div 
                onClick={() => {
                  if (isDayToday(day)) {
                    onDatesChangeHandler({
                      startDate: moment(),
                      endDate: moment()
                    });
                  }
                }}
                className={`${isDayToday(day) ? 'text-primary-600 font-bold hover:bg-primary-50 cursor-pointer rounded-full' : ''}`}
              >
                {day.date()}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};