import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormProvider } from "../contexts/FormContext";
import Layout from "../layout";
import InvoiceTable from './components/InvoiceTable';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';

const DueDateInvoices: React.FC = () => {
  return (
    <Layout>
      <Breadcrumb 
        items={[
          {label: "Dashboard", href: "/accounting"},
          {label: "Faturalar", href: "/accounting/invoices"},
          {label: "İleri Tarihli Faturalar", href: "/accounting/invoices/due-date-invoices"},
        ]} 
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
          <h1 className="text-2xl font-bold mb-6">İleri Tarihli Faturalar</h1>
          <InvoiceTable isDueDate={true} />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};

export default DueDateInvoices; 