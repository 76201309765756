import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  Tooltip,
  Snackbar,
  Alert,
  Autocomplete,
  Chip
} from "@mui/material"
import moment from "moment"
import { PictureAsPdf as PdfIcon, DirectionsCar, Hotel, TransferWithinAStation, Flight, Email } from "@mui/icons-material"
import currencyFormat from "utils/currencyFormat"
import { Link } from "react-router-dom"
import postFetcher from "api/helpers/postFetcher"
import { getInvoiceSendEmailUrl } from "api/helpers/api-constants"

interface InvoiceDetailDialogProps {
  open: boolean
  onClose: () => void
  invoiceDetail: any
  loading: boolean
}

const InvoiceDetailDialog: React.FC<InvoiceDetailDialogProps> = ({ open, onClose, invoiceDetail, loading }) => {
  const [emailList, setEmailList] = useState<string[]>([])
  const [showEmailInput, setShowEmailInput] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState("")
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">("success")
  const [sending, setSending] = useState(false)
  
  useEffect(() => {
    // If invoice detail has an agency email, pre-populate it
    if (invoiceDetail && invoiceDetail.agencyCustomerEmail) {
      setEmailList([invoiceDetail.agencyCustomerEmail]);
    }
  }, [invoiceDetail]);

  if (!invoiceDetail) return null

  const handleShowPdf = () => {
    const pdfUrl = `https://www.atrtouristik.com/agency/mars/invoices/${invoiceDetail.invoiceNumber}.pdf`;
    // Use a more reliable way to open the PDF in a new tab
    const newWindow = window.open();
    if (newWindow) {
      newWindow.opener = null; // Prevents the new page from being able to access the window.opener property
      newWindow.location.href = pdfUrl;
    } else {
      // Fallback in case popup blockers prevent the window from opening
      alert("Popup blocker may be preventing the PDF from opening. Please allow popups for this site.");
    }
  }

  const handleSendEmail = async () => {
    if (!showEmailInput) {
      setShowEmailInput(true)
      return
    }

    if (!emailList.length) {
      setSnackbarMessage("Lütfen geçerli bir email adresi giriniz")
      setSnackbarSeverity("error")
      setSnackbarOpen(true)
      return
    }

    setSending(true)
    try {
      const response = await postFetcher(
        getInvoiceSendEmailUrl(),
        {
          invoiceNumber: invoiceDetail.invoiceNumber,
          emailList: emailList
        }
      )

      if (response.success) {
        setSnackbarMessage("Email başarıyla gönderildi")
        setSnackbarSeverity("success")
        setShowEmailInput(false)
      } else {
        setSnackbarMessage("Email gönderimi başarısız: " + (response.remarks || "Bilinmeyen hata"))
        setSnackbarSeverity("error")
      }
    } catch (error) {
      console.error("Error sending email:", error)
      setSnackbarMessage("Email gönderimi başarısız: " + (error as any).remarks || "Bilinmeyen hata")
      setSnackbarSeverity("error")
    } finally {
      setSending(false)
      setSnackbarOpen(true)
    }
  }

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle className="flex justify-between items-center bg-gray-100 border-b">
        <div className="flex items-center">
          {invoiceDetail.productType === "RENTALCAR" && <DirectionsCar className="mr-2" />}
          {invoiceDetail.productType === "HOTEL" && <Hotel className="mr-2" />}
          {invoiceDetail.productType === "TRANSFER" && <TransferWithinAStation className="mr-2" />}
          {invoiceDetail.productType === "FLIGHT" && <Flight className="mr-2" />}
          <span className="font-bold text-xl">Kayıt Detayı</span>
        </div>
        <div className="flex items-center space-x-2">
          {showEmailInput && (
            <Autocomplete
              multiple
              freeSolo
              options={[]}
              value={emailList}
              onChange={(_, newValue) => setEmailList(newValue)}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    size="small"
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  placeholder="Email addresses"
                />
              )}
              sx={{ width: 350 }}
            />
          )}
          <Button 
            startIcon={<Email />} 
            variant="contained" 
            color="primary" 
            onClick={handleSendEmail}
            disabled={sending}
          >
            {showEmailInput 
              ? (sending ? "Gönderiliyor..." : "Gönder") 
              : "Email Gönder"}
          </Button>
          <Button startIcon={<PdfIcon />} variant="contained" color="secondary" onClick={handleShowPdf}>
            Show PDF
          </Button>
        </div>
      </DialogTitle>
      <DialogContent className="bg-gray-50 p-6">
        <div className=" mx-auto bg-white rounded-lg shadow-md p-6 mb-6 border">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div>
              <p className="text-sm font-semibold mb-1">Fatura Numarası: <span className="font-normal">{invoiceDetail.invoiceNumber}</span></p>
              <p className="text-sm font-semibold mb-1">Rezervasyon Referansı:  
                <span className="font-normal">
                  <Link 
                    to={`/booking/detail/${invoiceDetail.bookingReference}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 hover:underline"
                  >
                    {invoiceDetail.bookingReference}
                  </Link>
                </span>
              </p>
              <p className="text-sm font-semibold mb-1">Ödeme Durumu: <span className="font-normal">{invoiceDetail.invoiceStatus}</span></p>
              <p className="text-sm font-semibold mb-1">Ürün Tipi: <span className="font-normal">{invoiceDetail.productType}</span></p>
            </div>
            <div>
              <p className="text-sm font-semibold mb-1">Acenta: <span className="font-normal">{invoiceDetail.agencyCustomerName}</span></p>
              <p className="text-sm font-semibold mb-1">Acenta Numarası: <span className="font-normal">{invoiceDetail.agencyCustomerNumber}</span></p>
              <p className="text-sm font-semibold mb-1">Seyahat Tarihi: <span className="font-normal">{moment(invoiceDetail.travelDate).format("DD-MM-YYYY")}</span></p>
              <p className="text-sm font-semibold mb-1">Seyahat Eden Yolcu: <span className="font-normal">{invoiceDetail.travelRegistrant}</span></p>
            </div>
          </div>
        </div>
        
        {/* Flight Bookings Section */}
        {invoiceDetail.flightBookings && invoiceDetail.flightBookings.length > 0 && (
          <div className="mb-6">
            {invoiceDetail.flightBookings.map((booking: any, index: number) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                <h3 className="text-lg font-bold mb-2 pb-2 border-b">
                  Uçuş Rezervasyonu
                </h3>
                <p className="text-sm font-semibold mb-1">Havayolu: <span className="font-normal">{booking.airline}</span></p>
                <p className="text-sm font-semibold mb-1">Tedarikçi: <span className="font-normal">{booking.supplier.supplierName}</span></p>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Uçuşlar
                </h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Uçuş Numarası</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Kalkış</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Varış</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Sınıf</th>
                      </tr>
                    </thead>
                    <tbody>
                      {booking.flightList.map((flight: any) => (
                        <tr key={flight.id} className="hover:bg-gray-50">
                          <td className="py-2 px-3 text-sm border">{flight.flightNumber}</td>
                          <td className="py-2 px-3 text-sm border">{`${flight.departurePort} (${moment(flight.departureDate).format("DD-MM-YYYY HH:mm")})`}</td>
                          <td className="py-2 px-3 text-sm border">{`${flight.arrivalPort} (${moment(flight.arrivalDate).format("DD-MM-YYYY HH:mm")})`}</td>
                          <td className="py-2 px-3 text-sm border">{flight.cabinClass}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Yolcular
                </h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Adı</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Tip</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Bilet Numarası</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Temel Fiyat</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Vergi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {booking.paxList.map((pax: any) => (
                        <tr key={pax.id} className="hover:bg-gray-50">
                          <td className="py-2 px-3 text-sm border">{`${pax.firstname} ${pax.surname}`}</td>
                          <td className="py-2 px-3 text-sm border">{pax.paxtype}</td>
                          <td className="py-2 px-3 text-sm border">{pax.ticketnumber}</td>
                          <td className="py-2 px-3 text-sm border">{currencyFormat(pax.baseFare)}</td>
                          <td className="py-2 px-3 text-sm border">{currencyFormat(pax.tax)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {/* Hotel Bookings Section */}
        {invoiceDetail.hotelBookings && invoiceDetail.hotelBookings.length > 0 && (
          <div className="mb-6">
            {invoiceDetail.hotelBookings.map((booking: any, index: number) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                <h3 className="text-lg font-bold mb-2 pb-2 border-b">
                  Otel Rezervasyonu
                </h3>
                <p className="text-sm font-semibold mb-1">Otel Adı: <span className="font-normal">{booking.hotelName}</span></p>
                <p className="text-sm font-semibold mb-1">Adres: <span className="font-normal">{booking.address}</span></p>
                <p className="text-sm font-semibold mb-1">Tedarikçi: <span className="font-normal">{booking.supplier?.supplierName}</span></p>
                <p className="text-sm font-semibold mb-1">Giriş Tarihi: <span className="font-normal">{moment(booking.checkinDate).format("DD-MM-YYYY")}</span></p>
                <p className="text-sm font-semibold mb-1">Çıkış Tarihi: <span className="font-normal">{moment(booking.checkoutDate).format("DD-MM-YYYY")}</span></p>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Oda Bilgileri
                </h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Oda Tipi</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Pansiyon</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Yetişkin</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Çocuk</th>
                      </tr>
                    </thead>
                    <tbody>
                      {booking.roomList && booking.roomList.map((room: any, roomIndex: number) => (
                        <tr key={roomIndex} className="hover:bg-gray-50">
                          <td className="py-2 px-3 text-sm border">{room.roomType}</td>
                          <td className="py-2 px-3 text-sm border">{room.board}</td>
                          <td className="py-2 px-3 text-sm border">{room.adultCount}</td>
                          <td className="py-2 px-3 text-sm border">{room.childCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Misafir Listesi
                </h4>
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Adı Soyadı</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Tip</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Cinsiyet</th>
                      </tr>
                    </thead>
                    <tbody>
                      {booking.guestList && booking.guestList.map((guest: any, guestIndex: number) => (
                        <tr key={guestIndex} className="hover:bg-gray-50">
                          <td className="py-2 px-3 text-sm border">{`${guest.firstname} ${guest.surname}`}</td>
                          <td className="py-2 px-3 text-sm border">{guest.paxtype}</td>
                          <td className="py-2 px-3 text-sm border">{guest.gender === "MALE" ? "Erkek" : "Kadın"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {/* Transfer Bookings Section */}
        {invoiceDetail.transferBookings && invoiceDetail.transferBookings.length > 0 && (
          <div className="mb-6">
            {invoiceDetail.transferBookings.map((booking: any, index: number) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                <h3 className="text-lg font-bold mb-2 pb-2 border-b">
                  Transfer Rezervasyonu
                </h3>
                <p className="text-sm font-semibold mb-1">Tedarikçi: <span className="font-normal">{booking.supplier?.supplierName}</span></p>
                <p className="text-sm font-semibold mb-1">Araç Tipi: <span className="font-normal">{booking.vehicleType}</span></p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                  <div>
                    <p className="text-sm font-semibold mb-1">Kalkış Yeri: <span className="font-normal">{booking.pickupLocation}</span></p>
                    <p className="text-sm font-semibold mb-1">Kalkış Tarihi: <span className="font-normal">{moment(booking.pickupDate).format("DD-MM-YYYY HH:mm")}</span></p>
                    <p className="text-sm font-semibold mb-1">Uçuş Numarası: <span className="font-normal">{booking.flightNumber}</span></p>
                    <p className="text-sm font-semibold mb-1">Uçuş Kalkış Yeri: <span className="font-normal">{booking.flightDeparturePort}</span></p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold mb-1">Varış Yeri: <span className="font-normal">{booking.destinationLocation}</span></p>
                    <p className="text-sm font-semibold mb-1">Dönüş Tarihi: <span className="font-normal">{moment(booking.returnDate).format("DD-MM-YYYY HH:mm")}</span></p>
                    <p className="text-sm font-semibold mb-1">Telefon: <span className="font-normal">{booking.mobilePhone}</span></p>
                  </div>
                </div>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Yolcu Bilgileri
                </h4>
                <p className="text-sm mb-2">
                  <span className="font-semibold">Yetişkin:</span> {booking.adtCount} | 
                  <span className="font-semibold ml-2">Çocuk:</span> {booking.chdCount} | 
                  <span className="font-semibold ml-2">Bebek:</span> {booking.infCount}
                </p>
                
                <div className="overflow-x-auto">
                  <table className="min-w-full border-collapse">
                    <thead className="bg-gray-100">
                      <tr>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Adı</th>
                        <th className="py-2 px-3 text-left text-sm font-bold border">Soyadı</th>
                      </tr>
                    </thead>
                    <tbody>
                      {booking.passengerList && booking.passengerList.map((passenger: any, passengerIndex: number) => (
                        <tr key={passengerIndex} className="hover:bg-gray-50">
                          <td className="py-2 px-3 text-sm border">{passenger.firstname}</td>
                          <td className="py-2 px-3 text-sm border">{passenger.surname}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}
          </div>
        )}
        
        {/* Rental Car Bookings Section */}
        {invoiceDetail.rentalCarBookings && invoiceDetail.rentalCarBookings.length > 0 && (
          <div className="mb-6">
            {invoiceDetail.rentalCarBookings.map((booking: any, index: number) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                <h3 className="text-lg font-bold mb-2 pb-2 border-b">
                  Araç Kiralama Rezervasyonu
                </h3>
                <p className="text-sm font-semibold mb-1">Araç: <span className="font-normal">{booking.vehicle}</span></p>
                <p className="text-sm font-semibold mb-1">Marka: <span className="font-normal">{booking.rentalCarBrand}</span></p>
                <p className="text-sm font-semibold mb-1">Grup: <span className="font-normal">{booking.rentalCarGroup}</span></p>
                <p className="text-sm font-semibold mb-1">Tedarikçi: <span className="font-normal">{booking.supplier?.supplierName}</span></p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-3">
                  <div>
                    <p className="text-sm font-semibold mb-1">Vites: <span className="font-normal">{booking.gearType}</span></p>
                    <p className="text-sm font-semibold mb-1">Yakıt: <span className="font-normal">{booking.fuelType}</span></p>
                    <p className="text-sm font-semibold mb-1">Yaş Sınırı: <span className="font-normal">{booking.ageLimit}</span></p>
                    <p className="text-sm font-semibold mb-1">Günlük Fiyat: <span className="font-normal">{currencyFormat(booking.dailyRentPrice)}</span></p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold mb-1">Toplam Gün: <span className="font-normal">{booking.totalDays}</span></p>
                    <p className="text-sm font-semibold mb-1">Depozito: <span className="font-normal">{currencyFormat(booking.deposit)}</span></p>
                    <p className="text-sm font-semibold mb-1">Ek Ücret: <span className="font-normal">{currencyFormat(booking.dropFee)}</span></p>
                    <p className="text-sm font-semibold mb-1">Sistem Ücreti: <span className="font-normal">{currencyFormat(booking.systemFee)}</span></p>
                  </div>
                </div>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Alış/Teslim Bilgileri
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-semibold mb-1">Alış Yeri: <span className="font-normal">{booking.pickupLocationName}</span></p>
                    <p className="text-sm font-semibold mb-1">Alış Tarihi: <span className="font-normal">{moment(booking.pickupDate).format("DD-MM-YYYY")}</span></p>
                  </div>
                  <div>
                    <p className="text-sm font-semibold mb-1">Teslim Yeri: <span className="font-normal">{booking.returnLocationName}</span></p>
                    <p className="text-sm font-semibold mb-1">Teslim Tarihi: <span className="font-normal">{moment(booking.returnDate).format("DD-MM-YYYY")}</span></p>
                  </div>
                </div>

                <h4 className="text-md font-bold mt-4 mb-2">
                  Kiracı Bilgileri
                </h4>
                <p className="text-sm font-semibold mb-1">Ad Soyad: <span className="font-normal">{booking.firstname} {booking.surname}</span></p>
                <p className="text-sm font-semibold mb-1">Telefon: <span className="font-normal">{booking.mobilePhone}</span></p>
                <p className="text-sm font-semibold mb-1">E-posta: <span className="font-normal">{booking.email}</span></p>
              </div>
            ))}
          </div>
        )}
        
        <div className="bg-white rounded-lg shadow-md p-6 border">
          <div className="flex justify-end items-center ">
            <div className="text-left">
              <p className="text-sm font-semibold mb-1">Toplam Satış Fiyatı: <span className="font-bold text-lg">{currencyFormat(invoiceDetail.totalSalesPrice)}</span></p>
              <p className="text-sm font-semibold mb-1">Ödenmemiş Bakiye: <span className="font-bold text-lg">{currencyFormat(invoiceDetail.outstandingBalance)}</span></p>
              <p className="text-sm font-semibold mb-1">Ödeme Durumu: <span className="font-normal">{invoiceDetail.paid ? "Evet" : "Hayır"}</span></p>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="bg-gray-100 border-t p-4">
        <Button onClick={onClose} variant="contained" color="primary">Kapat</Button>
      </DialogActions>
      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Dialog>
  )
}

export default InvoiceDetailDialog

