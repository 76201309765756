import React from 'react';
import { Button } from 'primereact/button';
import { useNavigate } from 'react-router-dom';
import ConstructionIcon from '@mui/icons-material/Construction';
import Layout from '../layout';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';

const SupplierPayments: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layout>    
      <Breadcrumb items={[{label: 'Tedarikçi Ödemeleri', href: '/accounting/supplier-payments'}]} />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 p-4">
        <div className="bg-white shadow-lg rounded-lg p-8 max-w-md w-full text-center">
          <div className="mb-6 flex justify-center">
            <ConstructionIcon style={{ fontSize: '4rem' }} className="text-blue-500" />
          </div>
          
          <h1 className="text-2xl font-bold mb-2">Sayfa Yapım Aşamasında</h1>
          <p className="text-gray-600 mb-8">
            Bu sayfa şu anda geliştiriliyor. Yakında burada tedarikçi ödemeleri yönetimi kullanılabilir olacak.
          </p>
          
          <div className="flex justify-center">
            <Button 
              label="Geri Dön" 
              icon="pi pi-arrow-left" 
              className="p-button-primary" 
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
        
        <div className="mt-8 text-sm text-gray-500">
          © {new Date().getFullYear()} Accounting System
        </div>
      </div>
    </Layout>
  );
};

export default SupplierPayments;
