import { Button, Checkbox, FormControl,  MenuItem, Select, TextField, FormControlLabel, InputLabel } from '@mui/material';
import React, { useState } from 'react';

interface ReportFiltersProps {
  onFilter: (filters: {
    paid: boolean | null;
    pastDueDates: boolean;
    nextDueDates: boolean;
    agencyName: string;
  }) => void;
}

export const ReportFilters: React.FC<ReportFiltersProps> = ({ onFilter }) => {
  const [paid, setPaid] = useState<boolean | null>(null);
  const [pastDueDates, setPastDueDates] = useState(true);
  const [nextDueDates, setNextDueDates] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  const [errors, setErrors] = useState<{ agencyNumber?: string; agencyName?: string }>({});

  const validateInputs = () => {
    const newErrors: typeof errors = {};
    
  
    
    if (agencyName) {
      if (agencyName.length > 20) {
        newErrors.agencyName = "Acente adı 20 karakteri geçemez";
      } else if (agencyName.length < 3) {
        newErrors.agencyName = "Acente adı en az 3 karakter olmalıdır";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFilter = () => {
    if (!validateInputs()) return;
    onFilter({  
      paid,
      pastDueDates,
      nextDueDates,
      agencyName,
    });
  };

  return (
    <div className="flex flex-col space-y-6 p-6 bg-white rounded-lg border border-gray-200">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="flex flex-col space-y-4 md:col-span-2">
          <div className="flex items-center gap-8">
          <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Ödeme Durumu</InputLabel>
              <Select
                value={paid === null ? 'all' : paid.toString()}
                onChange={(e) => {
                  const value = e.target.value;
                  setPaid(value === 'all' ? null : value === 'true');
                }}
                label="Ödeme Durumu"
              >
                <MenuItem value="all">Tüm Kayıtlar</MenuItem>
                <MenuItem value="true">Ödenmiş</MenuItem>
                <MenuItem value="false">Ödenmemiş</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={pastDueDates}
                  onChange={() => {
                    setPastDueDates(prev => {
                      const newValue = !prev;
                      if (newValue) setNextDueDates(false);
                      return newValue;
                    });
                  }}
                  name="pastDueDates"
                />
              }
              label="Geçmiş Vade Tarihleri"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={nextDueDates}
                  onChange={() => {
                    setNextDueDates(prev => {
                      const newValue = !prev;
                      if (newValue) setPastDueDates(false);
                      return newValue;
                    });
                  }}
                  name="nextDueDates"
                />
              }
              label="Gelecek Vade Tarihleri"
            />
          </div>
        </div>
        
       
        <TextField
          name="agencyName"
          label="Acente Adı"
          value={agencyName}
          variant="outlined"
          size="small"
          onChange={(e) => {
            setAgencyName(e.target.value);
            setErrors(prev => ({ ...prev, agencyName: '' }));
          }}
          error={Boolean(errors.agencyName)}
          helperText={errors.agencyName}
          sx={{ width: '100%' }}
        />
        <div className="flex justify-end">
         <Button
          variant="outlined"
          onClick={handleFilter}
          sx={{
            height: 40,
            px: 4,
            py: 2,
            textTransform: 'none'
          }}
        >
          Filtrele
        </Button>
        </div>
      </div>
       
    </div>
  );
}; 