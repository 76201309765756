import React, { useEffect, useState } from 'react';
import { ColumnDef, SortingState, PaginationState } from '@tanstack/react-table';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { useGetData, PaginatedResponse } from 'pages/Accounting/hooks/useGetData';
import { Agency } from 'pages/Accounting/types/agency';
import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';
import { AgencyFilters } from './AgencyFilters';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { getAgencySearchUrl } from 'api/helpers/api-constants';

const columns: ColumnDef<Agency>[] = [
  {
    accessorKey: 'number',
    header: 'Acente No',
  },
  {
    accessorKey: 'name',
    header: 'Acente Adı',
    size: 200,
  },
  {
    accessorKey: 'balance',
    header: 'Bakiye',
    cell: ({ getValue }) => {
      const balance = getValue() as number;
      return (
        <span className={balance < 0 ? 'text-red-600' : 'text-green-600'}>
          {currencyFormat(balance)}
        </span>
      );
    },
  },
  {
    accessorKey: 'cityName',
    header: 'Şehir',
  },
  {
    accessorKey: 'active',
    header: 'Durum',
    cell: ({ row }) => {
      const active = row.original.active;
      const hasSepa = row.original.hasSepa;
      
      return (
        <div className="flex flex-col gap-1">
          {active 
            ? <span className="text-green-600 border border-green-100 bg-green-100 rounded-md px-2 py-1">Aktif</span> 
            : <span className="text-red-600 border border-red-100 bg-red-100 rounded-md px-2 py-1">Pasif</span>}
          
          {hasSepa 
            ? <span className="text-blue-600 border border-blue-100 bg-blue-100 rounded-md px-2 py-1">Sepa Var</span>
            : <span className="text-gray-600 border border-gray-100 bg-gray-100 rounded-md px-2 py-1">Sepa Yok</span>}
        </div>
      );
    },
  },
  {
    accessorKey: 'sepaLimit',
    header: 'Sepa Limit',
    cell: ({ getValue }) => {
      const sepaLimit = getValue() as number;
      return currencyFormat(sepaLimit);
    },
  },
  {
    accessorKey: 'lastPurchaseTransactionDate',
    header: 'Son İşlem Tarihi',
    cell: ({ getValue }) => {
      const date = getValue() as string;
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '-';
    },
  },
];

interface AgencyListProps {
}

export const AgencyList: React.FC<AgencyListProps> = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 1000 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState({
    isActive: true,
    hasSepa: true as boolean | undefined,
    agencyNumber: '',
    agencyName: '',
  });

  const { 
    data, 
    isLoading, 
    isError, 
    refetch 
  } = useGetData<PaginatedResponse<Agency>, any>(
    getAgencySearchUrl,
    {
      pageSize: pagination.pageSize,
      activePage: pagination.pageIndex,
      isActive: filters.isActive,
      ...(filters.hasSepa !== undefined && { hasSepa: filters.hasSepa }),
      agencyNumber: filters.agencyNumber,
      agencyName: filters.agencyName,
    },
    { 
      result: [], 
      pageSize: 0, 
      activePage: 0, 
      totalCount: 0, 
      totalPages: 0 
    }
  );

  const handleRowClick = (agency: Agency) => {
    console.log(agency);
    navigate(`/accounting/agency/${agency.number}`);
  };

  const handleFilter = (newFilters: {
    isActive: boolean;
    hasSepa?: boolean;
    agencyNumber: string;
    agencyName: string;
  }) => {
    setFilters({
      ...newFilters,
      hasSepa: newFilters.hasSepa
    });
    setPagination({ ...pagination, pageIndex: 0 }); 
  };

  if (isError) return <div>Error loading agencies</div>;

  return (
    <div className="p-6 space-y-4">      
      <AgencyFilters onFilter={handleFilter} />

      <TableComponent
        data={data?.result || []}
        columns={columns}
        manualPagination={true}
        manualSorting={false}
        sorting={sorting}
        onSortingChange={setSorting}
        pagination={pagination}
        onPaginationChange={setPagination}
        onRowClick={handleRowClick}
        totalRecords={data?.totalCount}
      />
      <LoadingComponent isLoading={isLoading} service="general" />

    </div>
  );
};
