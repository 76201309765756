import React, { useEffect, useState } from 'react';
import { ColumnDef, SortingState, PaginationState } from '@tanstack/react-table';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { useGetData, PaginatedResponse } from 'pages/Accounting/hooks/useGetData';
import { Agency } from 'pages/Accounting/types/agency';
import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';
import { ReportFilters } from './components/ReportFilters';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { getAgencyDebtorsUrl, getAgencySearchUrl } from 'api/helpers/api-constants';

const columns: ColumnDef<Agency>[] = [
  {
    accessorKey: 'number',
    header: 'Acente No',
  },
  {
    accessorKey: 'name',
    header: 'Acente Adı',
  },
  {
    accessorKey: 'debtAmount',
    header: 'Bakiye',
    cell: ({ getValue }) => {
      const balance = getValue() as number;
      return (
        <span className={`font-medium text-red-600`}>
          {currencyFormat(balance)}
        </span>
      );
    },
  },
  {
    accessorKey: 'lastPurchaseTransactionDate',
    header: 'Son İşlem Tarihi',
    cell: ({ getValue }) => getValue() ? moment(getValue() as string).format('DD.MM.YYYY') : '-',
  },
];

interface ReportDebtorsProps {
}

export const ReportDebtors: React.FC<ReportDebtorsProps> = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState<{
    paid: boolean | null;
    pastDueDates: boolean;
    nextDueDates: boolean;
    agencyName: string;
  }>({
    paid: null,
    pastDueDates: true,
    nextDueDates: false,
    agencyName: '',
  });

  const { 
    data, 
    isLoading, 
    isError, 
    refetch 
  } = useGetData<PaginatedResponse<Agency>, any>(
    getAgencyDebtorsUrl,
    filters.paid !== null ? {
      paid: filters.paid,
      pastDueDates: filters.pastDueDates,
      nextDueDates: filters.nextDueDates,
      ...(filters.agencyName && { agencyName: filters.agencyName }),
      pageSize: pagination.pageSize,
      activePage: pagination.pageIndex,
    } : {
      pastDueDates: filters.pastDueDates,
      nextDueDates: filters.nextDueDates,
      ...(filters.agencyName && { agencyName: filters.agencyName }),
      pageSize: pagination.pageSize,
      activePage: pagination.pageIndex,
      },
    { 
      result: [], 
      pageSize: 0, 
      activePage: 0, 
      totalCount: 0, 
      totalPages: 0 
    }
  );

  const handleRowClick = (agency: Agency) => {
    navigate(`/accounting/agency/${agency.number}`);
    return;
  };

  const handleFilter = (newFilters: {
    paid: boolean | null;
    pastDueDates: boolean;
    nextDueDates: boolean;
    agencyName: string;
  }) => {
    setFilters(newFilters);
    setPagination({ ...pagination, pageIndex: 0 }); 
  };

  if (isError) return <div>Error loading agencies</div>;

  return (
    <div className="p-6 space-y-4">      
      <ReportFilters onFilter={handleFilter} />

      <TableComponent
        data={data?.result || []}
        columns={columns}
        manualPagination={true}
        manualSorting={false}
        sorting={sorting}
        onSortingChange={setSorting}
        pagination={pagination}
        onPaginationChange={setPagination}
        onRowClick={handleRowClick}
        totalRecords={data?.totalCount}
        sumColumnId="debtAmount"
      />
      <LoadingComponent isLoading={isLoading} service="general" />

    </div>
  );
};
