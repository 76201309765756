import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../layout';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';

const ReportExportLinks = () => {
  return (
    <Layout>
        <Breadcrumb
            items={[
                { label: 'Muhasebe', href: '/accounting' },
                { label: 'Günlük Muhasebe Raporu', href: '/accounting/reports/export-links' },
            ]}
        />
      <h1 className="text-2xl font-bold mb-6">Muhasebe Raporu Export Linkleri</h1>
      
      <div className="max-w-4xl  flex flex-col space-y-4  h-screen">
        <div className="flex flex-row space-x-4">
          <a
            href="https://www.atrtouristik.com/api/export/receipt"
            className="border-2 border-blue-500 text-blue-500 hover:bg-blue-50 font-medium py-2 px-4 rounded-md transition-colors duration-200 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Günlük Muhasebe Raporu indir
          </a>

          <a
            href="https://www.atrtouristik.com/api/export/product-supplier"
            className="border-2 border-green-500 text-green-500 hover:bg-green-50 font-medium py-2 px-4 rounded-md transition-colors duration-200 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            Tedarikçi listesi indir
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default ReportExportLinks;
