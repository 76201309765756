import React, { useState, useEffect } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  ColumnDef,
  flexRender,
  SortingState,
  getSortedRowModel,
  PaginationState,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { format } from 'date-fns';
import { Invoice } from 'pages/Accounting/types/invoice';
import { useGetInvoices } from 'pages/Accounting/hooks/useGetInvoices';
import { useGetDetail } from 'pages/Accounting/hooks/useGetInvoiceDetail';
import InvoiceDetailDialog from './InvoiceDetailDialog';
import { CircularProgress } from '@material-ui/core';
import moment from 'moment';
import { FiltersState, InvoiceFilters } from './InvoiceFilters';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';

export const invoiceColumns: ColumnDef<Invoice>[] = [
  {
    accessorFn: (row) => row.creationDate,
    accessorKey: 'creationDate',
    id: 'creationDate',
    header: 'Oluşturma Tarihi',
    size: 80,
    cell: ({ row }) => {
      const creationDate = new Date(row.original.creationDate);
      const date = format(creationDate, 'dd.MM.yyyy');
      const time = format(creationDate, 'HH:mm');
      
      return (
        <div className="flex flex-col">
          <span className="font-medium">{date}</span>
          <span className="text-xs text-gray-500">{time}</span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.agencyCustomerNumber,
    accessorKey: 'agencyCustomerNumber',
    id: 'agencyCustomerNumber',
    header: 'Acenta Bilgisi',
    size: 200,
    cell: ({ row }) => (
      <div className="flex flex-col">
        <span className="font-medium">{row.original.agencyCustomerName}</span>
        <span className="text-xs text-gray-00">{row.original.agencyCustomerNumber}</span>
      </div>
    ),
  },
  {
    accessorFn: (row) => row.travelDate,
    accessorKey: 'travelDate',
    id: 'travelDate',
    header: 'Seyahat Tarihi',
    size: 180,
    cell: ({ row }) => {
      const travelDate = new Date(row.original.travelDate);
      const date = format(travelDate, 'dd.MM.yyyy');
      const time = format(travelDate, 'HH:mm');
      
      return (
        <div className="flex flex-col">
          <span className="font-medium">{date}</span>
          <span className="text-xs text-gray-500">{time}</span>
        </div>
      );
    },
  },
  {
    accessorFn: (row) => row.paid,
    accessorKey: 'paid',
    id: 'paid',
    header: 'Ödeme Durumu',
    size: 140,
    cell: ({ getValue }) => {
      const isPaid = getValue() as boolean;
      return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${
          isPaid ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
        }`}>
          {isPaid ? 'Ödendi' : 'Ödenmedi'}
        </span>
      );
    },
  },
  {
    accessorFn: (row) => row.invoiceStatus,
    accessorKey: 'invoiceStatus',
    id: 'invoiceStatus',
    header: 'Durum',
    size: 120,
    cell: ({ getValue }) => {
      const status = getValue() as string;
      const styles = status === 'DRAFT' 
        ? 'bg-gray-100 text-gray-800'
        : 'bg-blue-100 text-blue-800';
      
      return (
        <span className={`px-2 py-1 rounded-full text-xs font-medium ${styles}`}>
          {status}
        </span>
      );
    },
  },
  {
    accessorFn: (row) => row.invoiceNumber,
    accessorKey: 'invoiceNumber',
    id: 'invoiceNumber',
    header: 'Fatura Numarası',
    size: 100,
  },
  {
    accessorFn: (row) => row.bookingReference,
    accessorKey: 'bookingReference',
    id: 'bookingReference',
    header: 'PNR',
    size: 100,
  },
  {
    accessorFn: (row) => row.productType,
    accessorKey: 'productType',
    id: 'productType',
    header: 'Ürün',
    size: 50,
  },
  {
    accessorKey: 'travelRegistrant',
    id: 'travelRegistrant',
    header: 'Seyahat Eden',
    size: 150,
    cell: ({ getValue }) => getValue() as string,
  },
  // {
  //   accessorFn: (row) => row.outstandingBalance,
  //   id: 'outstandingBalance',
  //   header: 'Outstanding',
  //   cell: ({ getValue }) => `€${(getValue() as number).toFixed(2)}`,
  // },
  {
    accessorFn: (row) => row.totalSalesPrice,
    accessorKey: 'totalSalesPrice',
    id: 'totalSalesPrice',
    header: 'Fiyat',
    size: 80,
    cell: ({ getValue }) => `${(getValue() as number).toFixed(2)} €`,
  },
 
];

interface InvoiceTableProps {
  defaultPaid?: boolean;
  isDueDate?: boolean;
  isInvoiced?: boolean;
  agencyNumber?: number;
}

export default function InvoiceTable({ defaultPaid, isDueDate, isInvoiced, agencyNumber }: InvoiceTableProps) {
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [filters, setFilters] = useState<FiltersState>({
    dateRange: {startDate: moment().subtract(1,"month"), endDate: moment()},
    invoiceNumber: '',
    bookingReference: '',
    productType: '',
    outstandingBalance: '',
    totalSalesPrice: '',
  
  });
  const { loading, errorDetail, invoiceDetail, fetchInvoiceDetail } = useGetDetail();
  const [dialogOpen, setDialogOpen] = useState(false);


  const [queryParams, setQueryParams] = useState({
    sEcho: 1,
    iDisplayStart: 0,
    iDisplayLength: 10,
    iSortingCols: 1,
    iSortCol_0: 0,
    mDataProp_0: 'creationDate',
    sSortDir_0: 'desc',
    paid: defaultPaid || false,
    isDueDate: isDueDate || false,
    isInvoiced: isInvoiced || false,
    agencyNumber: agencyNumber || '',
    ...filters,
    startDate: filters.dateRange.startDate ? moment(filters.dateRange.startDate).format('DD.MM.YYYY') : '',
        endDate: filters.dateRange.endDate ? moment(filters.dateRange.endDate).format('DD.MM.YYYY') : '',
  });

  const { data, error, isValidating, mutate } = useGetInvoices(queryParams);
  
  const handleViewDetails = (invoiceNumber: string) => {
    fetchInvoiceDetail(invoiceNumber);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleFilterChange = (name: string, value: string | moment.Moment | null) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const handleFilterSubmit = () => {
    if (isValidating) return;

    setPagination(prev => ({ ...prev, pageIndex: 0 }));
    
    const newParams = {
      ...queryParams,
      iDisplayStart: 0,
      iDisplayLength: pagination.pageSize,
      ...filters,
      startDate: filters.dateRange.startDate?.format('DD.MM.YYYY') || '',
      endDate: filters.dateRange.endDate?.format('DD.MM.YYYY') || '',
    };

    // Only update if params actually changed
    if (JSON.stringify(queryParams) !== JSON.stringify(newParams)) {
      setQueryParams(newParams);
      mutate();
    }
  };
  useEffect(() => {
    setQueryParams((prev) => {
      const updated = {
        ...prev,
        iDisplayStart: pagination.pageIndex * pagination.pageSize,
        iDisplayLength: pagination.pageSize,
        iSortCol_0: invoiceColumns.findIndex((col) => col.id === sorting[0]?.id),
        mDataProp_0: sorting[0]?.id || 'creationDate',
        sSortDir_0: sorting[0]?.desc ? 'desc' : 'asc',
        ...filters,
        startDate: filters.dateRange.startDate ? moment(filters.dateRange.startDate).format('DD.MM.YYYY') : '',
        endDate: filters.dateRange.endDate ? moment(filters.dateRange.endDate).format('DD.MM.YYYY') : '',
        isInvoiced: isInvoiced || false,
        paid: defaultPaid || false,
        isDueDate: isDueDate || false,
        agencyNumber: agencyNumber || '',
      };

      
      return JSON.stringify(prev) === JSON.stringify(updated) ? prev : updated;
    });
  }, [pagination, sorting]);
  

  

 

  if (error) return <div>An error occurred: {error.message}</div>;
  return (
    <div className="w-full bg-white p-6 rounded-lg border border-gray-200">
      <InvoiceFilters
        filters={filters}
        onFilterChange={handleFilterChange}
        onFilterSubmit={handleFilterSubmit}
      />

      <TableComponent
        data={data?.aaData || []}
        columns={invoiceColumns}
        sorting={sorting}
        onSortingChange={setSorting}
        pagination={pagination}
        onPaginationChange={setPagination}
        totalRecords={data?.iTotalRecords}
        onRowClick={(row) => handleViewDetails(row.invoiceNumber)}
        manualPagination={true}
        manualSorting={true}
        sumColumnId="totalSalesPrice"
      />

      <LoadingComponent isLoading={loading || isValidating} service="general" />
      <InvoiceDetailDialog
        loading={loading}
        open={dialogOpen && !loading}
        onClose={handleCloseDialog}
        invoiceDetail={invoiceDetail}
      />
    </div>
  );
}

