import React, { createContext, useContext, useState, ReactNode } from 'react';
import moment from 'moment';
import { PaymentAccount, Payments } from '../types/invoice';

export type ProductType = "FLIGHT" | "RENTALCAR" | "HOTEL" | "GENERIC" | "TRANSFER"

interface Passenger {
  id: number;
  paxType: 'ADT' | 'CHD' | 'INF';
  gender: 'MALE' | 'FEMALE';
  name: string;
  surname: string;
  ticketNumber: string;
  baseFare: number;
  tax: number;
  serviceFee: number;
  passengerProductType:'FLIGHT' | 'RENTALCAR' | 'HOTEL' | 'GENERIC' | 'TRANSFER';
  birthdate?: string;
  hiddenServiceFee?: number;
}

interface Flight {
  id: number;
  airline: string;
  flightNumber: string;
  class: 'Economy' | 'Business' | 'First';
  from: string;
  to: string;
  baggage: string;
  stopover: string;
  departureDate: moment.Moment | null;
  arrivalDate: moment.Moment | null;
  classOfService: string;
  airlineFileKey: string;
  bookingPNR: string;
}

 interface CarRental {
  id: number;
  carType: string;
  pickupDate: moment.Moment;
  returnDate: moment.Moment;
  pickupLocation: string;
  returnLocation: string;
  dailyRentPrice: number;
  dropFee: number;
  systemFee: number;
  deposit: number;
  depositPaymentType: string;
}

interface HotelBooking {
  id: number;
  hotelName: string;
  checkInDate: moment.Moment;
  checkOutDate: moment.Moment;
  roomType: string;
  board: string;
  numberOfGuests: number;
  pricePerNight: number;
}
interface GenericProduct {
  id: number;
  name: string;
  quantity: number;
  description: string;
  price: number;
}
interface Transfer {
  id: number;
  pickupLocation: string;
  destinationLocation: string;
  pickupDate: moment.Moment;
  returnDate: moment.Moment;
  vehicleType: string;
  flightNumber: string;
  flightDeparturePort: string;
  flightDate: string;
  adtCount: number;
  chdCount: number;
  infCount: number;
  mobilePhone: string;
}
interface FormData {
  invoiceDetails: {
    customerId: string;
    customerName: string;
    productSupplierId: string;
    paymentAccountId: string;
    productRefId: string;
    productTypes: ProductType[];
    invoiceType: 'regular' | 'refund';
    invoiceStatus:'DRAFT' | 'INVOICED'
    isJupiterBooking: boolean,
    invoiceDueDate: moment.Moment | null
  };
  allPassengers: {
    [key in ProductType]: {
      [productId: number]: Passenger[]
    }
  }
  flights: Flight[];
  carRentals: CarRental[];
  hotelBookings: HotelBooking[];
  genericProducts: GenericProduct[];
  transfers: Transfer[];
  payments:Payments[]
}

interface FormContextType {
  formData: FormData;
  updateFormData: (newData: Partial<FormData>) => void;
}

const FormContext = createContext<FormContextType | undefined>(undefined);

export const FormProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [formData, setFormData] = useState<FormData>({
    invoiceDetails: {
      customerId: '',
      customerName: '',
      productSupplierId: '',
      paymentAccountId: '',
      productRefId: '',
      productTypes: ['FLIGHT'],
      invoiceType: 'regular',
      invoiceStatus: 'INVOICED',
      isJupiterBooking: false,
      invoiceDueDate: moment()
    },
    allPassengers: {
      FLIGHT: {},
      RENTALCAR: {},
      HOTEL: {},
      GENERIC: {},
      TRANSFER: {},
    },
    flights: [],
    carRentals: [],
    hotelBookings: [],
    genericProducts: [],
    transfers: [],
    payments: [],
  });

  const updateFormData = (newData: Partial<FormData>) => {
    setFormData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  return (
    <FormContext.Provider value={{ formData, updateFormData }}>
      {children}
    </FormContext.Provider>
  );
};


export const useFormContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};

export { 
  type FormData, 
  type Passenger, 
  type Flight, 
  type CarRental, 
  type HotelBooking, 
  type GenericProduct,
  type Transfer
};

