import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { TextField, Button, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { PaginatedResponse } from 'pages/Accounting/hooks/useGetData';
import { getProductSupplierLookupUrl } from 'api/helpers/api-constants';
import { useGetData } from 'pages/Accounting/hooks/useGetData';
import { ProductSupplierLookup, ServiceOperator } from 'pages/Accounting/types/productSupplierLookup';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui';

interface ReportFilterSuplierProps {
  onFilter: (filters: {
    supplierId: string;
    operatorServiceId: string;
    fromDate: Moment | null;
    toDate: Moment | null;
    productType: string;
  }) => void;
}

const productTypes = [
    {label: 'Flight', value: 'FLIGHT'},
    {label: 'Hotel', value: 'HOTEL'},
    {label: 'Rent a Car', value: 'RENTALCAR'},
    {label: 'Transfer', value: 'TRANSFER'},
    {label: 'Tour', value: 'TOUR'},
]

export const ReportFilterSuplier: React.FC<ReportFilterSuplierProps> = ({ onFilter }) => {
  const { data, isLoading } = useGetData<PaginatedResponse<ProductSupplierLookup>, any>(
    getProductSupplierLookupUrl,
    {}
  );

  const [supplierId, setSupplierId] = useState('');
  const [operatorServiceId, setOperatorServiceId] = useState('');
  const [productType, setProductType] = useState('');
  const [errors, setErrors] = useState<{ supplierId?: string }>({});
  const [fromDate, setFromDate] = useState<Moment>(moment().subtract(1, 'month').startOf('month'));
  const [toDate, setToDate] = useState<Moment>(moment().endOf('month'));

  const getServiceOperators = () => {
    const selectedSupplier = data?.result.find(s => s.id === Number(supplierId));
    return selectedSupplier?.serviceOperators || [];
  };

  const validateInputs = () => {
    const newErrors: typeof errors = {};
    return Object.keys(newErrors).length === 0;
  };

  const handleFromDateChange = (date: Moment | null) => {
    if (date) setFromDate(date);
  };

  const handleToDateChange = (date: Moment | null) => {
    if (date) setToDate(date);
  };

  const handleReset = () => {
    setSupplierId('');
    setOperatorServiceId('');
    setProductType('');
    setFromDate(moment().subtract(1, 'month').startOf('month'));
    setToDate(moment().endOf('month'));
    setErrors({});
    
    // Trigger filter with reset values
    onFilter({
      supplierId: '',
      operatorServiceId: '',
      fromDate: moment().subtract(1, 'month').startOf('month'),
      toDate: moment().endOf('month'),
      productType: ''
    });
  };

  const handleFilter = () => {
    if (!validateInputs()) return;
    onFilter({  
      supplierId,
      operatorServiceId,
      fromDate,
      toDate,
      productType
    });
  };

  return (
    <>
      <LoadingComponent isLoading={isLoading}></LoadingComponent>
      {data && !isLoading && (
        <div className="flex flex-col space-y-6 p-6 bg-white rounded-lg border border-gray-200">
          <div className="flex flex-row gap-4">
            <FormControl sx={{ width: '20%' }} size="small">
              <InputLabel>Tedarikçi</InputLabel>
              <Select
                value={supplierId}
                onChange={(e) => setSupplierId(e.target.value)}
                label="Tedarikçi"
              >
                {data?.result.map((supplier) => (
                  <MenuItem key={supplier.id} value={supplier.id}>
                    {supplier.productSupplierCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: '15%' }} size="small" disabled={!supplierId}>
              <InputLabel>Operatör</InputLabel>
              <Select
                value={operatorServiceId}
                onChange={(e) => setOperatorServiceId(e.target.value)}
                label="Operatör"
              >
                {getServiceOperators().map((operator: ServiceOperator) => (
                  <MenuItem key={operator.id} value={operator.id}>
                    {operator.serviceOperatorCode}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ width: '15%' }} size="small">
              <InputLabel>Ürün Tipi</InputLabel>
              <Select
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                label="Ürün Tipi"
              >
                {productTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            
            <div className="flex items-center gap-2" style={{ width: '25%' }}>
              <DatePickermui
                date={fromDate}
                handleDateChange={handleFromDateChange}
                className="w-full"
              />
              <span className="mx-1">-</span>
              <DatePickermui
                date={toDate}
                handleDateChange={handleToDateChange}
                className="w-full"
              />
            </div>
                 
            <Button
              variant="outlined"
              onClick={handleReset}
              sx={{ height: 40, px: 4, py: 2, textTransform: 'none' }}
            >
              Sıfırla
            </Button>
            <Button
              variant="outlined"
              onClick={handleFilter}
              sx={{ height: 40, px: 4, py: 2, textTransform: 'none' }}
            >
              Filtrele
            </Button>
          </div>
          {errors.supplierId && <div className="text-red-500 text-sm">{errors.supplierId}</div>}
        </div>
      )}
    </>
  );
}; 