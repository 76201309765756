import React, {FC} from "react"
import {FlightTrip} from "../../api/types";
import {useTranslation} from "react-i18next";
import parseTime from "../../utils/parseTime";
import {useReduxReducer} from "../../redux/store";
import { Chip } from 'primereact/chip';
import { getStaticUrl } from "utils/getStaticUrl";
import { MdAirlineSeatReclineNormal, MdLuggage } from 'react-icons/md';

interface IFlightCardRow {
    flightTrip: FlightTrip
    availableSeats?: number
    src: string | null
}

export const FlightCardRow: FC<IFlightCardRow> = ({flightTrip, availableSeats, src}) => {
    const {t} = useTranslation();
    const { isMobile } = useReduxReducer(state => state.general)

    const stops = flightTrip.flightList.slice(1);
    return (
        <>
            <div className={`w-full grid grid-cols-4 ${isMobile && 'pr-2'}`}>

                {
                    isMobile && (
                        <div className={`flex items-center justify-end text-neutral-500 col-start-2 col-span-3 whitespace-nowrap p-1 w-full gap-x-2 pb-1`}>
                            <>
                                <Chip className={`text-xs font-semibold`} label={parseTime(flightTrip.totalDurationInSeconds)}/>
                                {availableSeats && (
                                    <Chip 
                                        className={`text-xs font-semibold`} 
                                        template={
                                            <div className="flex items-center gap-1">
                                                <MdAirlineSeatReclineNormal />
                                                <span>{availableSeats}</span>
                                            </div>
                                        }
                                    />
                                )}
                                <Chip 
                                    className={`text-xs font-semibold`} 
                                    template={
                                        <div className="flex items-center gap-1">
                                            <MdLuggage />
                                            <span>{flightTrip.baggageAllowance}</span>
                                        </div>
                                    }
                                />
                                <Chip className={`text-xs font-semibold`} label={flightTrip.classOfService}/>
                            </>
                        </div>
                    )
                }

                <div className={`${isMobile && ''} col-span-1 flex items-center`}>
                    {
                        <div className="w-20 lg:w-28 flex flex-col items-center">
                            <img
                                src={getStaticUrl('b2b') + flightTrip.airline.logo}
                                className="w-14"
                                alt={flightTrip.airline.name}
                            />
                            <div className="text-xs text-neutral-500 mt-1">
                                {flightTrip.flightList[0]?.flightNumber}
                            </div>
                        </div>
                    }
                </div>
                <div className={`${ isMobile ? 'col-span-3' : 'col-span-2' } flex items-center space-x-8`}>
                    <div>
                        <div className="text-md font-semibold lg:text-xl">{flightTrip.departureTime}</div>
                        <div className="text-sm lg:text-lg font-normal text-neutral-500">
                            {flightTrip.origin.iataCode}
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex h-[2px] w-full items-center justify-around bg-neutral-300">
                            {
                                stops.length === 0 && (
                                    <div className="relative">
                                        <div className="h-3 w-3 border-2 border-transparent"/>
                                        <div className="absolute left-1/2 -translate-x-1/2 lg:text-sm text-xs text-neutral-500">
                                            {t("react.flight.nonstop")}
                                        </div>
                                    </div>
                                )
                            }

                            {
                                stops.map((stop, i) => (
                                    <div key={i} className="relative">
                                        <div className="h-3 w-3 border-2 border-neutral-200 bg-white"/>
                                        <div className="absolute left-1/2 -translate-x-1/2 lg:text-sm text-xs text-neutral-500">
                                            {stop.departurePort}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div>
                        <div className="text-md font-semibold lg:text-xl">{flightTrip.arrivalTime}</div>
                        <div className="text-sm lg:text-lg font-normal text-neutral-500">
                            {flightTrip.destination.iataCode}
                        </div>
                    </div>
                </div>

                {
                    !isMobile && (
                        <div className={`flex items-center justify-end text-neutral-500 flex-col col-span-1 text-sm lg:text-lg`}>
                            <>
                                <div>{parseTime(flightTrip.totalDurationInSeconds)}</div>
                                {availableSeats && (
                                    <div className="flex items-center gap-1 mb-1">
                                        <MdAirlineSeatReclineNormal />
                                        <span>{availableSeats} {t("rentalcar.seats")}</span>
                                    </div>
                                )}
                                <div className="flex items-center gap-1">
                                    <MdLuggage />
                                    <span>{flightTrip.baggageAllowance}&nbsp;&ndash;&nbsp;{flightTrip.classOfService}</span>
                                </div>
                            </>
                        </div>
                    )
                }

            </div>
        </>
    );
}
