import { ArrowLeft, Close } from '@mui/icons-material';
import React from 'react';

const NoPermissionPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-xl shadow-lg p-8 text-center space-y-6">
        <div className="flex justify-center">
          <Close className="w-20 h-20 text-red-500" />
        </div>
        
        <div className="space-y-3">
          <h1 className="text-3xl font-bold text-gray-900">Access Denied</h1>
          <p className="text-gray-600">
            Sorry, you don't have permission to view this page. Please contact your administrator if you think this is a mistake.
          </p>
        </div>

        <div className="pt-4">
          <button 
            onClick={() => window.history.back()} 
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-150 ease-in-out"
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            Go Back
          </button>
        </div>
      </div>

      
    </div>
  );
};

export default NoPermissionPage;