import React, { useState } from 'react';
import Layout from '../layout';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { Card } from 'primereact/card';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Snackbar, Alert } from '@mui/material';

interface BankAccount {
  id: number;
  bankName: string;
  accountHolder: string;
  iban: string;
  bic?: string;
}

const bankAccounts: BankAccount[] = [
  {
    id: 1,
    bankName: 'VOLKSBANK FRANKFURT',
    accountHolder: 'A.T.R. Touristik Service GmbH',
    iban: 'DE90501900006003105022',
    bic: 'FFVBDEFFXXX'
  },
  {
    id: 2,
    bankName: 'VOLKSBANK FRANKFURT',
    accountHolder: 'A.T.R. Touristik Service GmbH',
    iban: 'DE37501900006003105006',
    bic: 'FFVBDEFFXXX'
  },
  {
    id: 3,
    bankName: 'SPARKASSE',
    accountHolder: 'A.T.R. TOURISTIK SERVICE GMBH',
    iban: 'DE57 5055 0020 0000 1945 81'
  },
  {
    id: 4,
    bankName: 'SPARKASSE',
    accountHolder: 'A.T.R.-TOURISTIK E.KFF.',
    iban: 'DE97 5055 0020 0000 1559 34'
  }
];

const BankList: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  
  const copyToClipboard = (text: string, type: string) => {
    navigator.clipboard.writeText(text);
    setToastMessage(`${type} kopyalandı!`);
    setOpen(true);
  };
  
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  
  return (
    <Layout>
      <Breadcrumb items={[{label: 'Banka Listesi', href: '/accounting/bank-list'}]} />
      
      <div className="p-4">
        <h1 className="text-2xl font-bold mb-6 flex items-center">
          <AccountBalanceIcon className="mr-2" /> Banka Hesapları
        </h1>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {bankAccounts.map((account) => (
            <Card key={account.id} className="shadow-md">
              <div className="p-4">
                <div className="bg-blue-50 rounded-lg p-3 mb-4">
                  <h2 className="text-xl font-bold text-blue-800">{account.bankName}</h2>
                </div>
                
                <div className="mb-4">
                  <div className="text-gray-600 text-sm mb-1">Hesap Sahibi</div>
                  <div className="font-medium">{account.accountHolder}</div>
                </div>
                
                <div className="mb-4">
                  <div className="text-gray-600 text-sm mb-1">IBAN</div>
                  <div className="flex items-center justify-between">
                    <code className="bg-gray-100 p-2 rounded font-medium flex-grow">
                      {account.iban}
                    </code>
                    <button 
                      className="ml-2 p-2 text-blue-600 hover:bg-blue-50 rounded-full"
                      onClick={() => copyToClipboard(account.iban, 'IBAN')}
                      title="IBAN'ı Kopyala"
                    >
                      <ContentCopyIcon fontSize="small" />
                    </button>
                  </div>
                </div>
                
                {account.bic && (
                  <div>
                    <div className="text-gray-600 text-sm mb-1">BIC/SWIFT</div>
                    <div className="flex items-center justify-between">
                      <code className="bg-gray-100 p-2 rounded font-medium flex-grow">
                        {account.bic}
                      </code>
                      <button 
                        className="ml-2 p-2 text-blue-600 hover:bg-blue-50 rounded-full"
                        onClick={() => copyToClipboard(account.bic!, 'BIC/SWIFT')}
                        title="BIC/SWIFT Kodunu Kopyala"
                      >
                        <ContentCopyIcon fontSize="small" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          ))}
        </div>
      </div>
      
      <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default BankList;
