import React, { useState, useEffect, useMemo } from 'react';
import { ColumnDef, PaginationState } from '@tanstack/react-table';
import moment, { Moment } from 'moment';
import { Card, CircularProgress, Grid, Paper, Typography, Box } from '@mui/material';
import { useGetInvoices } from '../hooks/useGetInvoices';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import currencyFormat from 'utils/currencyFormat';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { format } from 'date-fns';
import { Invoice } from 'pages/Accounting/types/invoice';
import { invoiceColumns } from '../Invoices/components/InvoiceTable';
import { SortingState } from '@tanstack/react-table';
import { useGetDetail } from '../hooks/useGetInvoiceDetail';
import InvoiceDetailDialog from '../Invoices/components/InvoiceDetailDialog';
import Layout from '../layout';
import { ReportQuickFilter } from './components/ReportQuickFilter';
import { PaginatedResponse } from '../hooks/useGetData';
import { useGetData } from '../hooks/useGetData';
import { Agency } from '../types/agency';
import { getTotalSalesUrl } from 'api/helpers/api-constants';
import { useSearchParams } from 'react-router-dom';

// Sales Summary component
const SalesSummary = ({ data, onProductTypeClick }: { 
  data: any, 
  onProductTypeClick: (type: string | null) => void 
}) => {
  if (!data) return null;
  
  return (
    <Paper elevation={2} className="p-4 mb-4">
      <Typography variant="h6" className="mb-2">Sales Summary</Typography>
      <Grid container spacing={2}>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick('FLIGHT')}
          >
            <Typography variant="subtitle1">Flight Sales</Typography>
            <Typography variant="h6">{ data.flightSalesAmount ? currencyFormat(data.flightSalesAmount) : 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick('HOTEL')}
          >
            <Typography variant="subtitle1">Hotel Sales</Typography>
            <Typography variant="h6">{ data.hotelSalesAmount ? currencyFormat(data.hotelSalesAmount) : 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick('TRANSFER')}
          >
            <Typography variant="subtitle1">Transfer Sales</Typography>
            <Typography variant="h6">{ data.transferSalesAmount ? currencyFormat(data.transferSalesAmount) : 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick('RENTAL_CAR')}
          >
            <Typography variant="subtitle1">Rental Car Sales</Typography>
            <Typography variant="h6">{ data.rentalCarSalesAmount ? currencyFormat(data.rentalCarSalesAmount) : 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick('TOUR')}
          >
            <Typography variant="subtitle1">Tour Sales</Typography>
            <Typography variant="h6">{ data.tourSalesAmount ? currencyFormat(data.tourSalesAmount) : 0}</Typography>
          </Box>
        </Grid>
        <Grid item xs>
          <Box 
            className="p-2 text-center cursor-pointer hover:bg-gray-100 rounded" 
            onClick={() => onProductTypeClick(null)}
          >
            <Typography variant="subtitle1">Total Sales</Typography>
              <Typography variant="h6">{ data.flightSalesAmount + 
              data.hotelSalesAmount + 
              data.transferSalesAmount + 
              data.rentalCarSalesAmount + 
              data.tourSalesAmount ? currencyFormat(
              data.flightSalesAmount + 
              data.hotelSalesAmount + 
              data.transferSalesAmount + 
              data.rentalCarSalesAmount + 
              data.tourSalesAmount
            ) : 0}</Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

const DailySalesReport: React.FC = () => {
  const [searchParams] = useSearchParams();
  const productTypeParam = searchParams.get('productType');
  
  const [filters, setFilters] = useState({
    fromDate: moment().startOf('day') as Moment,
    toDate: moment().endOf('day') as Moment,
    agencyNumber: '',
    productType: productTypeParam as string | null,
  });
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sorting, setSorting] = useState<SortingState>([
    { id: 'creationDate', desc: true },
  ]);
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1000,
  });

  const queryParams = {
    sEcho: 1,
    iDisplayStart: 0,
    iDisplayLength: 1000, 
    iSortingCols: 1,
    iSortCol_0: 0,
    mDataProp_0: 'creationDate',
    sSortDir_0: 'desc',
    agencyNumber: filters.agencyNumber,
    startDate: filters.fromDate.format('DD.MM.YYYY'),
    endDate: filters.toDate.format('DD.MM.YYYY'),
    ...(filters.productType ? { productType: filters.productType } : {}),
  };

  const { data, isValidating } = useGetInvoices(queryParams);


  const { loading, errorDetail, invoiceDetail, fetchInvoiceDetail } = useGetDetail();
  
  const handleViewDetails = (invoiceNumber: string) => {
    fetchInvoiceDetail(invoiceNumber);
    setDialogOpen(true);
  };

  const handleFilter = (newFilters: {
    agencyNumber: string;
    fromDate: Moment | null;
    toDate: Moment | null;
  }) => {
    setFilters({
      ...filters,
      agencyNumber: newFilters.agencyNumber,
      fromDate: newFilters.fromDate || moment().subtract(1, 'day').startOf('day'),
      toDate: newFilters.toDate || moment().endOf('day')
    });
  };

  const handleProductTypeClick = (productType: string | null) => {
    setFilters(prev => ({
      ...prev,
      productType,
    }));
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  // Use this effect to update the filters when URL parameters change
  useEffect(() => {
    if (productTypeParam) {
      setFilters(prev => ({
        ...prev,
        productType: productTypeParam,
      }));
    }
  }, [productTypeParam]);

  return (
    <Layout>
      <div className="p-6 space-y-4">
        <Breadcrumb 
          items={[
            {label: "Dashboard", href: "/accounting"},
            {label: "Reports", href: "/accounting/reports"},
            {label: "Daily Sales", href: "/accounting/reports/daily-sales"},
          ]} 
        />

        <ReportQuickFilter    
          onFilter={handleFilter}
          agencyNumber={filters.agencyNumber}
        />


        {filters.productType && (
          <Box className="p-2 mb-4">
            <Typography variant="subtitle1">
              Filtered by: {filters.productType.replace('_', ' ')}
              <span 
                className="ml-2 cursor-pointer text-blue-500 hover:underline" 
                onClick={() => handleProductTypeClick(null)}
              >
                (Clear)
              </span>
            </Typography>
          </Box>
        )}

        <TableComponent
          data={data?.aaData || []}
          columns={invoiceColumns}
          pagination={pagination}
          sorting={sorting}
          onSortingChange={setSorting}
          onPaginationChange={setPagination}
          totalRecords={data?.iTotalRecords || 0}
          onRowClick={(row) => handleViewDetails(row.invoiceNumber)}
          manualPagination={false}
          manualSorting={false}
          sumColumnId="totalSalesPrice"
          sumRowPosition="top"
        />

        <LoadingComponent isLoading={loading || isValidating } service="general" />
        <InvoiceDetailDialog
          loading={loading}
          open={dialogOpen && !loading}
          onClose={handleCloseDialog}
          invoiceDetail={invoiceDetail}
        />
      </div>
    </Layout>
  );
};

export default DailySalesReport; 