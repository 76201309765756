import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';

interface DatePickerProps {
  date: moment.Moment;
  handleDateChange: (date: moment.Moment | null) => void;
  className?: string;
  error?: string;
}

export const DatePickermui: React.FC<DatePickerProps> = ({
  date,
  handleDateChange,
  className = '',
  error = '',
}) => {
  return (
    <div className={className}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          value={date}
          onChange={handleDateChange}
          format="DD.MM.YYYY"
          className={className}
          slotProps={{
            textField: {
              fullWidth: true,
              error: !!error,
              helperText: error,
              placeholder: "DD.MM.YYYY",
              variant: "outlined",
              size: "small",
              sx: { 
                '& .MuiInputBase-root': {
                  height: '40px'
                }
              }
            },
            field: {
              shouldRespectLeadingZeros: true,
            },
          }}
          closeOnSelect={false}
          // Enable manual input
          onError={(reason, value) => {
            // This allows invalid inputs during typing
            if (reason === 'invalidDate' && value) {
              const parsedDate = moment(value, 'DD.MM.YYYY', true);
              if (parsedDate.isValid()) {
                handleDateChange(parsedDate);
              }
            }
          }}
        />
      </LocalizationProvider>
    </div>
  );
};

