import React, { useEffect, useState } from 'react';
import { ColumnDef, SortingState, PaginationState } from '@tanstack/react-table';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { useGetData, PaginatedResponse } from 'pages/Accounting/hooks/useGetData';
import { Agency } from 'pages/Accounting/types/agency';
import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { getAgencyPurchasesBySupplierUrl } from 'api/helpers/api-constants';
import { Moment } from 'moment';
import { ReportFilterSuplier } from './components/ReportFilterSuplier';

const columns: ColumnDef<Agency>[] = [
  {
    accessorKey: 'productSupplierCode',
    header: 'Tedarikçi Kodu',
  },
  {
    accessorKey: 'serviceOperatorCode',
    header: 'Operatör Kodu',
  },
  {
    accessorKey: 'productType',
    header: 'Ürün Tipi',
  },
  {
    accessorKey: 'totalPurchaseAmount',
    header: 'Toplam Satın Alma',
    cell: ({ getValue }) => currencyFormat(getValue() as number || 0)
  },
  {
    accessorKey: 'paidAmount',
    header: 'Ödenen Tutar',
    cell: ({ getValue }) => currencyFormat(getValue() as number || 0)
  },
  {
    accessorKey: 'pendingAmount',
    header: 'Bekleyen Tutar',
    cell: ({ getValue }) => (
      <span className="font-medium text-red-600">
        {currencyFormat(getValue() as number || 0)}
      </span>
    )
  }
];

interface ReportSuplierProps {
}

export const ReportSuplier: React.FC<ReportSuplierProps> = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState({
    supplierId: '',
    operatorServiceId: '',
    fromDate: moment().subtract(1, 'month').startOf('month') as Moment | null,
    toDate: moment().endOf('month') as Moment | null,
    productType: ''
  });

  const { 
    data, 
    isLoading, 
    isError, 
    refetch 
  } = useGetData<PaginatedResponse<Agency>, any>(
    getAgencyPurchasesBySupplierUrl,
    {
        productSupplierId: filters.supplierId,
      serviceOperatorId: filters.operatorServiceId,
      fromDate: moment(filters.fromDate).format('YYYY-MM-DD'),
      toDate: moment(filters.toDate).format('YYYY-MM-DD'),
      productType: filters.productType || '',
      pageSize: pagination.pageSize,
      activePage: pagination.pageIndex,
    },
    { 
      result: [], 
      pageSize: 0, 
      activePage: 0, 
      totalCount: 0, 
      totalPages: 0 
    }
  );

  const handleRowClick = (agency: Agency) => {
    return;
  };

  const handleFilter = (newFilters: {
    supplierId: string;
    operatorServiceId: string;
    fromDate: Moment | null;
    toDate: Moment | null;
    productType: string;
  }) => {
    setFilters(newFilters);
    setPagination({ ...pagination, pageIndex: 0 });
  };

  if (isError) return <div>Error loading agencies</div>;

  return (
    <div className="p-6 space-y-4">      
      <ReportFilterSuplier onFilter={handleFilter} />

      <TableComponent
        data={data?.result || []}
        columns={columns}
        manualPagination={true}
        manualSorting={false}
        sorting={sorting}
        onSortingChange={setSorting}
        pagination={pagination}
        onPaginationChange={setPagination}
        onRowClick={handleRowClick}
        totalRecords={data?.totalCount}
        sumColumnId="pendingAmount"
      />
      <LoadingComponent isLoading={isLoading} service="general" />

    </div>
  );
};
