import React, { useEffect, useState } from 'react';
import { ColumnDef, SortingState, PaginationState } from '@tanstack/react-table';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { useGetData } from 'pages/Accounting/hooks/useGetData';
import { Agency, AgencyLookToBook } from 'pages/Accounting/types/agency';
import moment from 'moment';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { getAgencyLookToBookUrl } from 'api/helpers/api-constants';
import { Moment } from 'moment';
import { ReportFilterDates } from './components/ReportFilterDates';
import Layout from '../layout';
import { ReportLooktoBookFilter } from './components/ReportLooktoBookFilter';

// Create a reusable function for ratio cell rendering
const renderRatioCell = (ratio: number) => {
  let colorClass = 'text-red-600';
  
  // For ratio calculation, we're getting searchCount/bookCount
  // Lower ratio is better (fewer searches per booking)
  if (ratio <= 10) {
    colorClass = 'text-green-600';  // Good: 10 or fewer searches per booking
  } else if (ratio <= 100) {
    colorClass = 'text-yellow-600'; // Medium: 11-100 searches per booking
  }
  // else red: more than 100 searches per booking
  
  return (
    <span className={`font-medium ${colorClass}`}>
      {ratio > 0 ? `1:${Math.round(ratio)}` : '-'}
    </span>
  );
};      

// Create column groups
const agencyColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'agencyNumber',
    header: 'Acente No',
  },
  {
    accessorKey: 'agencyName',
    header: 'Acente Adı',
  },
];

const flightColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'flightSearchCount',
    header: 'Arama Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'flightReservationCount',
    header: 'Rezervasyon Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'flightBookCount',
    header: 'Biletleme Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    id: 'flightRatio',
    header: 'Oran',
    accessorFn: (row) => {
      const searchCount = row.flightSearchCount || 0;
      const bookCount = row.flightBookCount || 0;
      return bookCount > 0 ? searchCount / bookCount : 0;
    },
    cell: ({ getValue }) => renderRatioCell(getValue() as number)
  },
];

const hotelColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'hotelSearchCount',
    header: 'Arama Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'hotelReservationCount',
    header: 'Rezervasyon Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'hotelBookCount',
    header: 'Biletleme Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    id: 'hotelRatio',
    header: 'Oran',
    accessorFn: (row) => {
      const searchCount = row.hotelSearchCount || 0;
      const bookCount = row.hotelBookCount || 0;
      return bookCount > 0 ? searchCount / bookCount : 0;
    },
    cell: ({ getValue }) => renderRatioCell(getValue() as number)
  },
];

const rentalCarColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'rentalCarSearchCount',
    header: 'Arama Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'rentalCarReservationCount',
    header: 'Rezervasyon Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'rentalCarBookCount',
    header: 'Biletleme Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    id: 'rentalCarRatio',
    header: 'Oran',
    accessorFn: (row) => {
      const searchCount = row.rentalCarSearchCount || 0;
      const bookCount = row.rentalCarBookCount || 0;
      return bookCount > 0 ? searchCount / bookCount : 0;
    },
    cell: ({ getValue }) => renderRatioCell(getValue() as number)
  },
];

const transferColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'transferSearchCount',
    header: 'Arama Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'transferReservationCount',
    header: 'Rezervasyon Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'transferBookCount',
    header: 'Biletleme Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    id: 'transferRatio',
    header: 'Oran',
    accessorFn: (row) => {
      const searchCount = row.transferSearchCount || 0;
      const bookCount = row.transferBookCount || 0;
      return bookCount > 0 ? searchCount / bookCount : 0;
    },
    cell: ({ getValue }) => renderRatioCell(getValue() as number)
  },
];

const tourColumns: ColumnDef<any>[] = [
  {
    accessorKey: 'tourSearchCount',
    header: 'Arama Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'tourReservationCount',
    header: 'Rezervasyon Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    accessorKey: 'tourBookCount',
    header: 'Biletleme Sayısı',
    cell: ({ getValue }) => getValue() as number || 0
  },
  {
    id: 'tourRatio',
    header: 'Oran',
    accessorFn: (row) => {
      const searchCount = row.tourSearchCount || 0;
      const bookCount = row.tourBookCount || 0;
      return bookCount > 0 ? searchCount / bookCount : 0;
    },
    cell: ({ getValue }) => renderRatioCell(getValue() as number)
  },
];

// Tab type definition
type TabType = 'flight' | 'hotel' | 'rentalCar' | 'transfer' | 'tour';

interface ReportSuplierProps {
}

export const ReportLooktoBook: React.FC<ReportSuplierProps> = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 1000 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [activeTab, setActiveTab] = useState<TabType>('flight');
  const [filters, setFilters] = useState({
   agencyNumber: '',
   fromDate: moment().subtract(30, 'days') as Moment | null,
   toDate: moment() as Moment | null,
  });

  const { 
    data, 
    isLoading, 
    isError, 
    refetch 
  } = useGetData<{result: Agency[]}, any>(
    getAgencyLookToBookUrl,
    filters.agencyNumber.length > 0 ? {
        agencyNumber: filters.agencyNumber,
        fromDate: moment(filters.fromDate).format('YYYY-MM-DD'),
        toDate: moment(filters.toDate).format('YYYY-MM-DD'),
    }
     : {
        fromDate: moment(filters.fromDate).format('YYYY-MM-DD'),
        toDate: moment(filters.toDate).format('YYYY-MM-DD'),    },
    { 
      result: [],    
     
    }
  );

  // Get columns based on active tab
  const getActiveColumns = () => {
    switch (activeTab) {
      case 'flight':
        return [...agencyColumns, ...flightColumns];
      case 'hotel':
        return [...agencyColumns, ...hotelColumns];
      case 'rentalCar':
        return [...agencyColumns, ...rentalCarColumns];
      case 'transfer':
        return [...agencyColumns, ...transferColumns];
      case 'tour':
        return [...agencyColumns, ...tourColumns];
      default:
        return [...agencyColumns, ...flightColumns];
    }
  };

  // Use a memoized version of the columns to ensure they update when the tab changes
  const activeColumns = React.useMemo(() => getActiveColumns(), [activeTab]);

  const handleRowClick = () => {
    return;
  };

  const handleFilter = (newFilters: {
   agencyNumber: string;
   fromDate: Moment | null;
   toDate: Moment | null;
  }) => {
    setFilters(newFilters);
    refetch();
  };

  if (isError) return <div>Error loading agencies</div>;

  return (
    <Layout>
      <div className="p-6 space-y-4">      
        <Breadcrumb 
          items={[
            {label: "Dashboard", href: "/accounting"},
            {label: "Raporlar", href: "/accounting/reports"},
            {label: "Look to Book", href: "/accounting/reports/look-to-book"},
          ]} 
        />
        <ReportLooktoBookFilter onFilter={handleFilter} />

        {/* Tab Navigation */}
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            <button
              onClick={() => {setActiveTab('flight'); setSorting([]);}}
              className={`${
                activeTab === 'flight'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-all duration-300`}
            >
              Uçuş
            </button>
            <button
              onClick={() => {setActiveTab('hotel'); setSorting([]);}}
              className={`${
                activeTab === 'hotel'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-all duration-300`}
            >
              Otel
            </button>
            <button
              onClick={() => {setActiveTab('rentalCar'); setSorting([]);}}
              className={`${
                activeTab === 'rentalCar'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-all duration-300`}
            >
              Araç
            </button>
            <button
              onClick={() => {setActiveTab('transfer'); setSorting([]);}}
              className={`${
                activeTab === 'transfer'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-all duration-300`}
            >
              Transfer
            </button>
            <button
              onClick={() => {setActiveTab('tour'); setSorting([]);}}
              className={`${
                activeTab === 'tour'
                  ? 'border-blue-500 text-blue-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none transition-all duration-300`}
            >
              Tur
            </button>
          </nav>
        </div>

        <TableComponent
          data={data?.result || []}
          columns={activeColumns}
          manualPagination={true}
          manualSorting={false}
          sorting={sorting}
          onSortingChange={setSorting}
          pagination={pagination}
          onPaginationChange={setPagination}
          onRowClick={handleRowClick}
          totalRecords={data?.result.length || 0}
          key={activeTab} 
        />
        <LoadingComponent isLoading={isLoading} service="general" />
      </div>
    </Layout>
  );
};
