import { Home } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom"; 

interface BreadcrumbProps {
  items: {
    label: string;
    href: string;
  }[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="Breadcrumb" className="max-w-full">
      <ol className="flex items-center space-x-2 my-2 px-3 py-2 ">
        <li>
          <Link to="/accounting" className="text-gray-500 hover:text-gray-700 flex items-center">
            <Home fontSize="small" aria-hidden="true" />
            <span className="sr-only">Home</span>
          </Link>
        </li>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <li className="text-gray-400">/</li>
            <li>
              {index === items.length - 1 ? (
                <span className="text-gray-900 font-semibold" aria-current="page">
                  {item.label}
                </span>
              ) : (
                <Link to={item.href} className="text-gray-600 hover:text-blue-600 transition-colors duration-200">
                  {item.label}
                </Link>
              )}
            </li>
          </React.Fragment>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
