import React from 'react';
import { 
  TextField, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Button,
  Grid,
  Paper,
  Typography,
  IconButton,
  Autocomplete
} from '@mui/material';
import { Delete, Add } from '@mui/icons-material';
import moment from 'moment';
import { useFormContext } from 'pages/Accounting/contexts/FormContext';
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui';
import PaxFormComponent from './PaxFormComponent';
import axios from 'axios';
import { getAirportsByKeywordUrl } from 'api/helpers/api-constants';
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

interface Airport {
  id: string;
  text: string;
}

interface FlightInfo {
  id: number;
  airline: string;
  flightNumber: string;
  classOfService: string;
  class: 'Economy' | 'Business' | 'First';
  from: string;
  to: string;
  baggage: string;
  stopover: string;
  departureDate: moment.Moment | null;
  arrivalDate: moment.Moment | null;
}

const FlightInformationForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();
  const [fromOptions, setFromOptions] = React.useState<Airport[]>([]);
  const [toOptions, setToOptions] = React.useState<Airport[]>([]);
  const [stopoverOptions, setStopoverOptions] = React.useState<Airport[]>([]);
  const [selectedFromAirports, setSelectedFromAirports] = React.useState<{[key: number]: Airport | null}>({});
  const [selectedToAirports, setSelectedToAirports] = React.useState<{[key: number]: Airport | null}>({});
  const [selectedStopoverAirports, setSelectedStopoverAirports] = React.useState<{[key: number]: Airport | null}>({});

  const searchAirports = async (keyword: string) => {
    try {
      const response = await axios.get(getAirportsByKeywordUrl(keyword, `query`, keyword));
      return response.data.results || [];
    } catch (error) {
      console.error('Error fetching airports:', error);
      return [];
    }
  };

  const addFlight = () => {
    const newFlightId = formData.flights.length > 0 
      ? Math.max(...formData.flights.map(f => f.id)) + 1 
      : 1;
    
    updateFormData({
      flights: [
        ...formData.flights,
        {
          id: newFlightId,
          airline: '',
          flightNumber: '',
          classOfService: '',
          class: 'Economy',
          from: '',
          to: '',
          baggage: '',
          stopover: '',
          departureDate: null,
          arrivalDate: null,
          airlineFileKey: '',
          bookingPNR: ''
        }
      ]
    });
    
    setSelectedFromAirports(prev => ({...prev, [newFlightId]: null}));
    setSelectedToAirports(prev => ({...prev, [newFlightId]: null}));
    setSelectedStopoverAirports(prev => ({...prev, [newFlightId]: null}));
  };
  const removeFlight = (id: number) => {
    updateFormData({
      flights: formData.flights.filter(f => f.id !== id)
    });
  };

  const updateFlight = (id: number, field: keyof (typeof formData.flights)[0], value: any) => {
    updateFormData({
      flights: formData.flights.map(f => 
        f.id === id ? {...f, [field]: value} : f
      )
    });
  };

  return (
    <div>
      {formData.flights.map((flight, index) => (
        <div key={flight.id} className="p-2 my-2 bg-light  relative border rounded-lg">
          <span className='flex justify-between items-center py-4'>
          <h2 className="font-semibold text-xl text-gray-800">
            Uçuş {index + 1}
          </h2>
          {formData.flights.length > 1 && (
            <IconButton 
              onClick={() => removeFlight(flight.id)}
              className=""
              color="error"
              size="small"
            >
              <Delete />
            </IconButton>
          )}
          </span>
                
         
          <Grid container spacing={2}>
            {/* First Row */}
            <Grid item xs={12} sm={2}>
              <TextField
                label="Havayolu"
                value={flight.airline}
                onChange={(e) => updateFlight(flight.id, 'airline', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Uçuş Numarası"
                value={flight.flightNumber}
                onChange={(e) => updateFlight(flight.id, 'flightNumber', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Servis Sınıfı"
                value={flight.classOfService}
                onChange={(e) => updateFlight(flight.id, 'classOfService', e.target.value)}
                fullWidth
              />
            </Grid>
              <Grid item xs={12} sm={2}>
              <TextField
                label="Airline FileKey / PNR"
                value={flight.airlineFileKey}
                onChange={(e) => updateFlight(flight.id, 'airlineFileKey', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Rezervasyon PNR"
                value={flight.bookingPNR}
                onChange={(e) => updateFlight(flight.id, 'bookingPNR', e.target.value)}
                fullWidth
              />
            </Grid>
            {/* Second Row */}
            <Grid item xs={12} sm={2}>
              <FormControl fullWidth>
                <InputLabel>Sınıf</InputLabel>
                <Select
                  value={flight.class}
                  label="Sınıf"
                  onChange={(e) => updateFlight(flight.id, 'class', e.target.value as 'Economy' | 'Business' | 'First')}
                >
                  <MenuItem value="Economy">Ekonomi</MenuItem>
                  <MenuItem value="Business">Business</MenuItem>
                  <MenuItem value="First">First</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateTimePicker
                label="Kalkış Tarihi"
                value={flight.departureDate ? moment(flight.departureDate) : null}
                onChange={(newValue) => {
                  updateFlight(flight.id, 'departureDate', newValue ? moment(newValue.toISOString()) : null);
                }}
                format="DD.MM.YYYY HH:mm"
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                options={fromOptions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.text}
                value={selectedFromAirports[flight.id] || null}
                onChange={(_, newValue) => {
                  setSelectedFromAirports(prev => ({
                    ...prev, 
                    [flight.id]: newValue
                  }));
                  updateFlight(flight.id, 'from', newValue ? newValue.id : '');
                }}
                onInputChange={async (_, newInputValue) => {
                  if (newInputValue.length >= 2) {
                    const results = await searchAirports(newInputValue);
                    setFromOptions(results);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nereden"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <DateTimePicker
                label="Varış Tarihi"
                value={flight.arrivalDate ? moment(flight.arrivalDate) : null}
                onChange={(newValue) => {
                  updateFlight(flight.id, 'arrivalDate', newValue ? moment(newValue.toISOString()) : null);
                }}
                format="DD MM YYYY HH:mm"
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                options={toOptions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.text}
                value={selectedToAirports[flight.id] || null}
                onChange={(_, newValue) => {
                  setSelectedToAirports(prev => ({
                    ...prev, 
                    [flight.id]: newValue
                  }));
                  updateFlight(flight.id, 'to', newValue ? newValue.id : '');
                }}
                onInputChange={async (_, newInputValue) => {
                  if (newInputValue.length >= 2) {
                    const results = await searchAirports(newInputValue);
                    setToOptions(results);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Nereye"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <TextField
                label="Bagaj"
                value={flight.baggage}
                onChange={(e) => updateFlight(flight.id, 'baggage', e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <Autocomplete
                options={stopoverOptions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.text}
                value={selectedStopoverAirports[flight.id] || null}
                onChange={(_, newValue) => {
                  setSelectedStopoverAirports(prev => ({
                    ...prev, 
                    [flight.id]: newValue
                  }));
                  updateFlight(flight.id, 'stopover', newValue ? newValue.id : '');
                }}
                onInputChange={async (_, newInputValue) => {
                  if (newInputValue.length >= 2) {
                    const results = await searchAirports(newInputValue);
                    setStopoverOptions(results);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Aktarma"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <PaxFormComponent productType='FLIGHT' productId={flight.id}/>

        </div>
      ))}
      <div className='flex justify-start items-center '>   <Button 
        variant="outlined" 
        color="primary" 
        onClick={addFlight}
        startIcon={<Add />}
        className="mt-4"
      >
        Uçuş Ekle
      </Button></div>
   
    </div>
  );
};

export default FlightInformationForm;

