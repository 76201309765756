import type React from "react"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import type { IDateRange } from "api/types"
import type { FocusedInputShape } from "react-dates"
import TextField from "@mui/material/TextField"
import moment, { Moment } from "moment"
import { Button, MenuItem } from "@mui/material"
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui'


export interface FiltersState {
  dateRange: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  };
  invoiceNumber: string;
  bookingReference: string;
  productType: string;
  outstandingBalance: string;
  totalSalesPrice: string;
  paid?: boolean;
  isDueDate?: boolean;
  customerName?: string;
  salesTax?: string;
  agencyNumber?: number;
}

interface InvoiceFiltersProps {
  filters: FiltersState
  onFilterChange: (name: string, value: any) => void
  onFilterSubmit: () => void
  datePickerIsOpen?: (isOpen: boolean) => void
}

export function InvoiceFilters({ filters, onFilterChange, onFilterSubmit, datePickerIsOpen }: InvoiceFiltersProps) {
  const { t } = useTranslation()
  const PRODUCT_TYPES = [
    { label: "Uçuş", value: "FLIGHT" },
    { label: "Araç Kiralama", value: "RENTALCAR" },
    { label: "Otel", value: "HOTEL" },
    { label: "Genel", value: "GENERIC" },
    { label: "Transfer", value: "TRANSFER" }
  ] as const

  const [focusedInput, setFocusedInput] = useState<FocusedInputShape | null>(null);


  const handleDatesChange = (newDateRange: IDateRange) => {
    onFilterChange("dateRange", newDateRange)
  }

  const handleStartDateChange = (date: moment.Moment | null) => {
    onFilterChange("dateRange", { ...filters.dateRange, startDate: date })
  }

  const handleEndDateChange = (date: moment.Moment | null) => {
    onFilterChange("dateRange", { ...filters.dateRange, endDate: date })
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(e.target.name, e.target.value)
  }

  return (
    <div className="mb-6 space-y-4">
    <div className="grid grid-cols-3 gap-4">
      <TextField
        label={"Fatura Numarası"}
        name="invoiceNumber"
        value={filters.invoiceNumber}
        onChange={handleInputChange}
        size="small"
        fullWidth
      />
      <TextField
        label={"Rezervasyon Numarası / PNR"}
        name="bookingReference"
        value={filters.bookingReference}
        onChange={handleInputChange}
        size="small"
        fullWidth
      />
      <TextField
        select
        label={"Ürün Tipi"}
        name="productType"
        value={filters.productType}
        onChange={handleInputChange}
        size="small"
        fullWidth
      >
        {PRODUCT_TYPES.map((product) => (
          <MenuItem key={product.value} value={product.value}>
            {product.label}
          </MenuItem>
        ))}
      </TextField>
  
      <TextField
        label={"Toplam Satış Fiyatı"}
        name="totalSalesPrice"
        type="number"
        value={filters.totalSalesPrice}
        onChange={handleInputChange}
        size="small"
        fullWidth
      />
      <TextField
        label={"Acente Numarası"}
        name="agencyNumber"
        value={filters.agencyNumber}
        onChange={handleInputChange}
        size="small"
        fullWidth
      />
      <div className="grid grid-cols-2 gap-2">
        <DatePickermui
          date={filters.dateRange.startDate || moment()}
          handleDateChange={handleStartDateChange}
          className="w-full"
        />
        <DatePickermui
          date={filters.dateRange.endDate || moment()}
          handleDateChange={handleEndDateChange}
          className="w-full"
        />
      </div>
    </div>
  
    <div className="flex justify-end">
    <Button 
          variant="outlined" 
          onClick={onFilterSubmit}
          sx={{
            height: 40,
            px: 4,
            py: 2,
            textTransform: 'none'
          }}
        >
          Filtrele
        </Button>
    </div>
  </div>
  
  )
}

