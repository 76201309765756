import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptIcon from '@mui/icons-material/Receipt';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AssessmentIcon from '@mui/icons-material/Assessment';
import BusinessIcon from '@mui/icons-material/Business';
import Navbar from './components/Navbar';
import { Container } from '@mui/material';

interface LayoutProps {
  children: React.ReactNode;
}

const navigation = [
  {
    icon: DashboardIcon,
    text: 'Anasayfa',
    href: '/accounting',
  },
  {
    icon: BookOnlineIcon,
    text: 'Kayıt Yönetimi',
    subnav: [
      { text: 'Yeni Kayıt Oluştur', href: '/accounting/invoices/createnew' },
      { text: 'Tüm Kayıtlar', href: '/accounting/invoices' },
    ],
  },
  {
    icon: AccountBalanceIcon,
    text: 'Muhasebe Yönetimi',
    subnav: [
      { text: 'Tedarikçi Ödemeleri', href: '/accounting/supplier-payments' },
      { text: 'Müşteri Ödemeleri', href: '/accounting/customer-payments' },
      { text: 'Wallet Yönetimi', href: '/accounting/wallet' },
      { text: 'Bankalar', href: '/accounting/banks-list' },
      { text: 'Ödeme Yöntemleri', href: '/accounting/payment-methods' },
      { text: 'Export Links', href: '/accounting/reports/export-links' },


      
    ],
  },
  {
    icon: ReceiptIcon,
    text: 'Fatura Yönetimi',
    subnav: [
      { text: 'Tüm Faturalar', href: '/accounting/invoices' },
      { text: 'Ödenmemiş Faturalar', href: '/accounting/invoices/unpaid' },
      { text: 'İleri Tarihli Faturalar', href: '/accounting/invoices/due-date-invoices' },
    ],
  },
  {
    icon: AssessmentIcon,
    text: 'Raporlar',
    href: '/accounting/reports',

    subnav: [
      { text: 'Günlük Satışlar', href: '/accounting/reports/daily-sales' },
      { text: 'Borclu/Alacaklı Acenta Listesi', href: '/accounting/reports/debs-creditors' },
      { text: 'Acenteye Göre Satışlar', href: '/accounting/reports/sales-by-agency' },
      { text: 'Tedarikçiye Göre Alımlar', href: '/accounting/reports/purchases-by-supplier' },
      { text: 'Acenta Look to Book', href: '/accounting/reports/look-to-book' },
    ],
  },
  {
    icon: BusinessIcon,
    text: 'Acente Yönetimi',
    subnav: [
      { text: 'Acente Listesi', href: '/accounting/agency' },
      { text: 'Acente Look to Book', href: '/accounting/reports/look-to-book' },
    ],
  },
];

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <><Navbar nav={navigation} />
    <Container className="bg-light pt-6" maxWidth="xl"> 
      {children}
    </Container></>
  );
};

export default Layout;

