import React, { FC, useState, FormEvent, ChangeEvent } from "react";
import { t } from "i18next";
import { 
  TextField, 
  Button, 
  Grid, 
  MenuItem, 
  FormControl, 
  InputLabel, 
  Select, 
  Snackbar, 
  Alert, 
  Typography, 
  Paper,
  Autocomplete,
  SelectChangeEvent
} from "@mui/material";
import { DatePickermui } from "components/ui/DatePicker/DatePickermui";
import { getGroupReservationInquiryUrl, getAirportsByKeywordUrl } from "api/helpers/api-constants";
import moment from 'moment';

interface AirportAutocompleteOption {
  id: string;
  text: string;
  iata?: string;
  name?: string;
  city?: string;
  country?: string;
}

interface GroupReservationFormProps {
  className?: string;
}

interface FormValues {
  email: string;
  phonenumber: string;
  groupname: string;
  grouptype: string;
  adtCount: number;
  chdCount: number;
  infCount: number;
  outwardFlightDate: moment.Moment | null;
  returnFlightDate: moment.Moment | null;
  notes: string;
}

interface ToastState {
  open: boolean;
  message: string;
  severity: "success" | "error" | "info" | "warning";
}

const GroupReservationForm: FC<GroupReservationFormProps> = ({ className = "" }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [departureAirport, setDepartureAirport] = useState<AirportAutocompleteOption | null>(null);
  const [arrivalAirport, setArrivalAirport] = useState<AirportAutocompleteOption | null>(null);
  const [returnDepartureAirport, setReturnDepartureAirport] = useState<AirportAutocompleteOption | null>(null);
  const [returnArrivalAirport, setReturnArrivalAirport] = useState<AirportAutocompleteOption | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [toast, setToast] = useState<ToastState>({
    open: false,
    message: "",
    severity: "info"
  });
  
  // Airport options for autocomplete
  const [departureOptions, setDepartureOptions] = useState<AirportAutocompleteOption[]>([]);
  const [arrivalOptions, setArrivalOptions] = useState<AirportAutocompleteOption[]>([]);
  const [returnDepartureOptions, setReturnDepartureOptions] = useState<AirportAutocompleteOption[]>([]);
  const [returnArrivalOptions, setReturnArrivalOptions] = useState<AirportAutocompleteOption[]>([]);
  
  const [formValues, setFormValues] = useState<FormValues>({
    email: "",
    phonenumber: "",
    groupname: "",
    grouptype: "Family",
    adtCount: 10,
    chdCount: 0,
    infCount: 0,
    outwardFlightDate: null,
    returnFlightDate: null,
    notes: ""
  });

  const handleCloseToast = () => {
    setToast({ ...toast, open: false });
  };

  const showToast = (message: string, severity: "success" | "error" | "info" | "warning") => {
    setToast({
      open: true,
      message,
      severity
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const name = e.target.name as string;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: parseInt(value, 10) || 0
    });
  };

  const handleOutwardDateChange = (date: moment.Moment | null) => {
    setFormValues({
      ...formValues,
      outwardFlightDate: date
    });
  };

  const handleReturnDateChange = (date: moment.Moment | null) => {
    setFormValues({
      ...formValues,
      returnFlightDate: date
    });
  };

  const getAirportLabel = (option: AirportAutocompleteOption | string) => {
    if (typeof option === 'string') return option;
    return option.text || '';
  };

  const searchAirports = async (keyword: string) => {
    if (keyword.length < 2) return [];
    
    try {
      const response = await fetch(getAirportsByKeywordUrl(keyword, 'query', keyword));
      const data = await response.json();
      return data.results || [];
    } catch (error) {
      console.error('Error fetching airports:', error);
      return [];
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    
    if (!formValues.email) {
      newErrors.email = t("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = t("Email is invalid");
    }
    
    if (!formValues.phonenumber) {
      newErrors.phonenumber = t("Phone number is required");
    }
    
    if (!formValues.groupname) {
      newErrors.groupname = t("Group name is required");
    }
    
    if (!formValues.grouptype) {
      newErrors.grouptype = t("Group type is required");
    }
    
    if (formValues.adtCount < 1) {
      newErrors.adtCount = t("At least 1 adult is required");
    }
    
    if (!formValues.outwardFlightDate) {
      newErrors.outwardFlightDate = t("Outward flight date is required");
    }
    
    if (!departureAirport) {
      newErrors.departureAirport = t("Departure airport is required");
    }
    
    if (!arrivalAirport) {
      newErrors.arrivalAirport = t("Arrival airport is required");
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);

    // Format dates to YYYY-MM-DD
    const formatDate = (date: moment.Moment | null) => {
      return date ? date.format('YYYY-MM-DD') : '';
    };

    const formData = new URLSearchParams();
    formData.append("email", formValues.email);
    formData.append("phonenumber", formValues.phonenumber);
    formData.append("groupname", formValues.groupname);
    formData.append("grouptype", formValues.grouptype);
    formData.append("adtCount", formValues.adtCount.toString());
    formData.append("chdCount", formValues.chdCount.toString());
    formData.append("infCount", formValues.infCount.toString());
    
    if (formValues.outwardFlightDate && departureAirport && arrivalAirport) {
      formData.append("outwardFlightDate", formatDate(formValues.outwardFlightDate));
      formData.append("outwardFlightDeparturePort", departureAirport.id);
      formData.append("outwardFlightArrivalPort", arrivalAirport.id);
    }
    
    if (formValues.returnFlightDate) {
      formData.append("returnFlightDate", formatDate(formValues.returnFlightDate));
      formData.append("returnFlightDeparturePort", returnDepartureAirport?.id || arrivalAirport?.id || "");
      formData.append("returnFlightArrivalPort", returnArrivalAirport?.id || departureAirport?.id || "");
    }
    
    if (formValues.notes) {
      formData.append("notes", formValues.notes);
    }

    try {
      const response = await fetch(getGroupReservationInquiryUrl(), {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formData,
      });
      
      const data = await response.json();

      if (data.success) {
        showToast(
          data.remarks 
            ? t(data.remarks) 
            : t("Group reservation inquiry submitted successfully"), 
          "success"
        );
        
        // Reset form
        setFormValues({
          email: "",
          phonenumber: "",
          groupname: "",
          grouptype: "Family",
          adtCount: 10,
          chdCount: 0,
          infCount: 0,
          outwardFlightDate: null,
          returnFlightDate: null,
          notes: ""
        });
        setDepartureAirport(null);
        setArrivalAirport(null);
        setReturnDepartureAirport(null);
        setReturnArrivalAirport(null);
      } else {
        showToast(
          data.remarks 
            ? t(data.remarks) 
            : t("Failed to submit group reservation inquiry"), 
          "error"
        );
      }
    } catch (error) {
      console.error("Error submitting group reservation inquiry:", error);
      showToast(t("An error occurred while submitting your inquiry"), "error");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Paper elevation={0} className={`p-4 ${className}`}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {/* First Row: Contact and Passenger Information */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                İletişim Bilgileri
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="E-posta *"
                    name="email"
                    type="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Telefon Numarası *"
                    name="phonenumber"
                    type="tel"
                    value={formValues.phonenumber}
                    onChange={handleInputChange}
                    error={!!errors.phonenumber}
                    helperText={errors.phonenumber}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Grup Adı *"
                    name="groupname"
                    value={formValues.groupname}
                    onChange={handleInputChange}
                    error={!!errors.groupname}
                    helperText={errors.groupname}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined" error={!!errors.grouptype}>
                    <InputLabel>Grup Tipi *</InputLabel>
                    <Select
                      name="grouptype"
                      value={formValues.grouptype}
                      onChange={handleSelectChange}
                      label="Grup Tipi *"
                      size="small"
                    >
                      <MenuItem value="Family">Aile</MenuItem>
                      <MenuItem value="Business">İş</MenuItem>
                      <MenuItem value="School">Okul</MenuItem>
                      <MenuItem value="Sports">Spor</MenuItem>
                      <MenuItem value="Religious">Dini</MenuItem>
                      <MenuItem value="Other">Diğer</MenuItem>
                    </Select>
                    {errors.grouptype && (
                      <Typography variant="caption" color="error">
                        {errors.grouptype}
                      </Typography>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Yolcu Bilgileri
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Yetişkinler *"
                    name="adtCount"
                    type="number"
                    InputProps={{ inputProps: { min: 1 } }}
                    value={formValues.adtCount}
                    onChange={handleNumberChange}
                    error={!!errors.adtCount}
                    helperText={errors.adtCount}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Çocuklar (2-11)"
                    name="chdCount"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={formValues.chdCount}
                    onChange={handleNumberChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Bebekler (0-2)"
                    name="infCount"
                    type="number"
                    InputProps={{ inputProps: { min: 0 } }}
                    value={formValues.infCount}
                    onChange={handleNumberChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Second Row: Flight Information */}
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Gidiş Uçuş Bilgileri
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePickermui
                    date={formValues.outwardFlightDate || moment()}
                    handleDateChange={handleOutwardDateChange}
                    className="w-full"
                    error={errors.outwardFlightDate}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Autocomplete
                    options={departureOptions}
                    getOptionLabel={getAirportLabel}
                    value={departureAirport}
                    onChange={(_, newValue) => {
                      setDepartureAirport(newValue);
                    }}
                    onInputChange={async (_, newInputValue) => {
                      if (newInputValue.length >= 2) {
                        const results = await searchAirports(newInputValue);
                        setDepartureOptions(results);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nereden *"
                        variant="outlined"
                        error={!!errors.departureAirport}
                        helperText={errors.departureAirport}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Autocomplete
                    options={arrivalOptions}
                    getOptionLabel={getAirportLabel}
                    value={arrivalAirport}
                    onChange={(_, newValue) => {
                      setArrivalAirport(newValue);
                    }}
                    onInputChange={async (_, newInputValue) => {
                      if (newInputValue.length >= 2) {
                        const results = await searchAirports(newInputValue);
                        setArrivalOptions(results);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Nereye *"
                        variant="outlined"
                        error={!!errors.arrivalAirport}
                        helperText={errors.arrivalAirport}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Dönüş Uçuş Bilgileri
              </Typography>
              
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePickermui
                    date={formValues.returnFlightDate || moment()}
                    handleDateChange={handleReturnDateChange}
                    className="w-full"
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Autocomplete
                    options={returnDepartureOptions}
                    getOptionLabel={getAirportLabel}
                    value={returnDepartureAirport}
                    onChange={(_, newValue) => {
                      setReturnDepartureAirport(newValue);
                    }}
                    onInputChange={async (_, newInputValue) => {
                      if (newInputValue.length >= 2) {
                        const results = await searchAirports(newInputValue);
                        setReturnDepartureOptions(results);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dönüş Nereden"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Autocomplete
                    options={returnArrivalOptions}
                    getOptionLabel={getAirportLabel}
                    value={returnArrivalAirport}
                    onChange={(_, newValue) => {
                      setReturnArrivalAirport(newValue);
                    }}
                    onInputChange={async (_, newInputValue) => {
                      if (newInputValue.length >= 2) {
                        const results = await searchAirports(newInputValue);
                        setReturnArrivalOptions(results);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Dönüş Nereye"
                        variant="outlined"
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>

            {/* Additional Notes */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Ek Notlar
              </Typography>
              
              <TextField
                fullWidth
                label="Notlar"
                name="notes"
                multiline
                rows={3}
                value={formValues.notes}
                onChange={handleInputChange}
                variant="outlined"
                size="small"
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} className="flex justify-center mt-4">
              <Button 
                type="submit" 
                variant="contained" 
                disabled={isSubmitting}
                size="large"
                className="bg-primary-6000 text-white hover:bg-primary-700"
              >
                {isSubmitting ? "Gönderiliyor..." : "Grup Talebini Gönder"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>

      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleCloseToast}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseToast} severity={toast.severity} sx={{ width: '100%' }}>
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default GroupReservationForm; 