import React from "react";
import { Helmet } from "react-helmet";
import GroupReservationForm from "components/common/HeroSearchForm/GroupReservationForm";
import { t } from "i18next";
import { Container } from "@mui/material";

const GroupReservationPage: React.FC = () => {
  return (
    <div className="nc-GroupReservationPage">
      <Helmet>
        <title>{t("Grup Rezervasyon İstek Formu")}</title>
      </Helmet>
      
        <Container>
          <div className="max-w-4xl mx-auto pt-10">
            <h2 className="text-3xl font-semibold text-center mb-10">
              {t("Grup Rezervasyon İstek Formu")}
            </h2>
            
            <div className="w-full bg-white border border-gray-200 rounded-2xl overflow-hidden">
              <div className="p-6 sm:p-8">
                <GroupReservationForm />
              </div>
            </div>
          </div>
        </Container>
    </div>
  );
};

export default GroupReservationPage; 