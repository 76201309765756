import React, { useState } from 'react';
import moment, { Moment } from 'moment';
import { TextField, Button } from '@mui/material';
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui';

interface ReportLooktoBookFilterProps {
  onFilter: (filters: {
    fromDate: Moment | null;
    toDate: Moment | null;
    agencyNumber: string;
  }) => void;
}

export const ReportLooktoBookFilter: React.FC<ReportLooktoBookFilterProps> = ({ onFilter }) => {
  const [agencyNumber, setAgencyNumber] = useState('');
  const [fromDate, setFromDate] = useState<Moment>(moment().subtract(30, 'days'));
  const [toDate, setToDate] = useState<Moment>(moment());
  const [errors, setErrors] = useState<{ agencyName?: string }>({});

  const handleFromDateChange = (date: Moment | null) => {
    if (date) setFromDate(date);
  };

  const handleToDateChange = (date: Moment | null) => {
    if (date) setToDate(date);
  };

  const handleFilter = () => {
    onFilter({  
      agencyNumber,
      fromDate,
      toDate,
    });
  };

  return (
    <div className="flex flex-col space-y-6 p-6 bg-white rounded-lg border border-gray-200">
      <div className="flex justify-between w-full"> 
        <TextField
          name="agencyNumber"
          label="Acente Numarası"
          value={agencyNumber}
          variant="outlined"
          size="small"
          onChange={(e) => setAgencyNumber(e.target.value)}
          sx={{ width: '20%' }}
        />
        
        <div className="flex items-center gap-2" style={{ width: '40%' }}>
          <DatePickermui
            date={fromDate}
            handleDateChange={handleFromDateChange}
            className="w-full"
          />
          <span className="mx-1">-</span>
          <DatePickermui
            date={toDate}
            handleDateChange={handleToDateChange}
            className="w-full"
          />
        </div>
             
        <Button 
          variant="outlined" 
          onClick={handleFilter}
          sx={{
            height: 40,
            px: 4,
            py: 2,
            textTransform: 'none'
          }}
        >
          Filtrele
        </Button>
      </div>
    </div>
  );
};