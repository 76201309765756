import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import FlightIcon from '@mui/icons-material/Flight';
import HotelIcon from '@mui/icons-material/Hotel';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PublicIcon from '@mui/icons-material/Public';
import SearchIcon from '@mui/icons-material/Search';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { useTranslation } from 'react-i18next';
import { useReduxReducer } from 'redux/store';

interface LoaderDialogProps {
  isLoading: boolean;
  onClose?: () => void;
  service?: 'flight' | 'hotel' | 'car' | 'all' | 'general' | 'rtsearch';
}

interface LoadingState {
  icon: React.ReactNode;
  message: string;
  subMessage: string;
}

const LoadingComponent: React.FC<LoaderDialogProps> = ({ 
  isLoading, 
  onClose,
  service = 'all'
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const {t} = useTranslation()
  const {header} = useReduxReducer(state => state.config)
  const loadingStates: Record<string, LoadingState[]> = {
    flight: [
      { icon: <FlightIcon sx={{ fontSize: 32 }} />, message: t("react.loading.flight.searching"), subMessage: t("react.loading.flight.findingbestroutes") },
      { icon: <PublicIcon sx={{ fontSize: 32 }} />, message: t("react.loading.flight.checkingairlines"), subMessage: t("react.loading.flight.comparingcarriers") }
    ],
    hotel: [
      { icon: <HotelIcon sx={{ fontSize: 32 }} />, message: t("react.loading.hotel.searching"), subMessage: t("react.loading.hotel.finding")},
      { icon: <ApartmentIcon sx={{ fontSize: 32 }} />, message: t("react.loading.hotel.checking-availibity"), subMessage: t("react.loading.hotel.confirming-rooms") },
    ],
    car: [
      { icon: <DirectionsCarIcon sx={{ fontSize: 32 }} />, message: t("react.loading.rentacar.searching"), subMessage: t("react.loading.rentacar.finding") },
      { icon: <SearchIcon sx={{ fontSize: 32 }} />, message: t("react.loading.rentacar.comparingrates"), subMessage: t("react.loading.rentacar.getbestdeals") }
    ],
    all: [
      { icon: <FlightIcon sx={{ fontSize: 32 }} />, message:  t("react.loading.flight.searching"), subMessage:  t("react.loading.flight.findingbestroutes") },
      { icon: <HotelIcon sx={{ fontSize: 32 }} />, message: t("react.loading.hotel.searching"), subMessage: t("react.loading.hotel.finding")},
      { icon: <DirectionsCarIcon sx={{ fontSize: 32 }} />, message: t("react.loading.rentacar.searching"), subMessage: t("react.loading.rentacar.finding") },
    ],
    general: [
      { icon: <PublicIcon sx={{ fontSize: 32 }} />, message: t("b2c-app.generals.loading"), subMessage: t("b2c-app.flights.calculate-price.please-wait") },
    ],
    rtsearch: [
      { icon: <CompareArrowsIcon sx={{ fontSize: 32 }} />, message: t("react.loading.rtsearch.searching"), subMessage: t("react.loading.rtsearch.findingbestroutes") },
      { icon: <SearchIcon sx={{ fontSize: 32 }} />, message: t("react.loading.rtsearch.comparingprices"), subMessage: t("react.loading.rtsearch.optimizingitinerary") },
    ]
  };

  const activeStates = loadingStates[service];

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setCurrentIndex((prev) => (prev + 1) % activeStates.length);
      }, 2000); 
      return () => clearInterval(timer);
    }
  }, [isLoading, activeStates.length]);

  const getIconColor = (service: string): string => {
    switch (service) {
      case 'flight': return '#156DA1'; // blue
      case 'hotel': return '#9c27b0'; // purple
      case 'car': return '#4caf50'; // green
      case 'rtsearch': return '#ff9800'; // orange
      default: return '#156DA1'; // default blue
    }
  };

  return (
    <Dialog
      open={isLoading}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          backdropFilter: 'blur(8px)',
          maxWidth: '425px',
          margin: '16px',
          borderRadius: '12px',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
        },
      }}
      style={{
        backdropFilter: 'blur(4px)',
      }}
    >
      <DialogContent>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '24px', gap: '24px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', padding: '12px 0' }}>
          <img
            src={header.defaultLogo}
            alt="Logo"
            style={{
              width: 'auto',
              height: 'auto',
              animation: 'pulse 2s infinite ease-in-out',
              filter: 'drop-shadow(0 4px 8px rgba(0, 0, 0, 0.2))',
            }}
          />
        </div>
          {/* Animated Icon */}
          <div style={{ position: 'relative', width: '80px', height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <div style={{ position: 'absolute', width: '80px', height: '80px', borderRadius: '50%', border: '3px solid #e5e7eb', borderTopColor: getIconColor(service), animation: 'spin 0.8s linear infinite' }} />
            <div style={{ color: getIconColor(service), animation: 'fadeInOut 2s ease-in-out infinite', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {activeStates[currentIndex].icon}
            </div>
          </div>

          {/* Loading Text */}
          <div style={{ textAlign: 'center', minHeight: '80px', width: '100%' }}>
            <h3 style={{ margin: '0 0 8px 0', fontSize: '1.125rem', fontWeight: 600, color: '#1f2937', animation: 'fadeInOut 2s ease-in-out infinite' }}>
              {activeStates[currentIndex].message}
            </h3>
            <p style={{ margin: 0, fontSize: '0.875rem', color: '#6b7280', animation: 'fadeInOut 2s ease-in-out infinite' }}>
              {activeStates[currentIndex].subMessage}
            </p>
          </div>

          {/* Loading Dots */}
          <div style={{ display: 'flex', gap: '8px' }}>
            {[0, 1, 2].map((i) => (
              <div key={i} style={{ width: '8px', height: '8px', backgroundColor: getIconColor(service), borderRadius: '50%', animation: 'bounce 1s infinite cubic-bezier(0.68, -0.55, 0.27, 1.55)', animationDelay: `${i * 0.2}s` }} />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

// Enhanced Keyframe Animations
const styles = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes fadeInOut {
    0%, 100% { opacity: 0.4; transform: scale(0.9); }
    50% { opacity: 1; transform: scale(1); }
  }

  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0.6); }
    40% { transform: scale(1.1); }
  }
`;

// Add styles to document
const styleSheet = document.createElement("style");
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default LoadingComponent;

