import useSWR from 'swr';
import fetcher from 'api/helpers/fetcher';


export interface PaginatedResponse<T> {
  result: T[];
  pageSize: number;
  activePage: number;
  totalCount: number;
  totalPages: number;
}

const fetchData = <T>(url: string): Promise<T> => fetcher(url);

export const useGetData = <T, Q = Record<string, unknown>>(
  getUrl: (params?: Q) => string | null,
  params?: Q,
  defaultFallbackData: T = {} as T
) => {
  const url = params ? getUrl(params) : getUrl();
  
  const { data, error, isValidating, mutate } = useSWR<T>(
    url,
    fetchData,
    {
      revalidateOnFocus: false,
      dedupingInterval: 60 * 1000,
      fallbackData: defaultFallbackData,
    }
  );

  const refetch = () => mutate();

  return { 
    data,
    isLoading: isValidating,
    isError: error,
    isValidating,
    refetch,
  };
};


