import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material"
import { DirectionsCar, Hotel, TransferWithinAStation, Flight } from "@mui/icons-material"
import moment from "moment"
import currencyFormat from "utils/currencyFormat"
import { Passenger, FormData, Flight as FlightType, HotelBooking, Transfer, CarRental } from "pages/Accounting/contexts/FormContext"

interface DraftPreviewDialogProps {
  open: boolean
  onClose: () => void
  formData: FormData
  onSubmit: () => Promise<any>
}

const DraftPreviewDialog: React.FC<DraftPreviewDialogProps> = ({ open, onClose, formData, onSubmit }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      const response = await onSubmit();
      console.log(response)
      if (response && response.status === 200) {
        setShowSuccess(true);
        // We don't close the dialog immediately
        // Let the snackbar be visible first, then close after delay
      }
    } catch (error) {
      console.error("Error submitting invoice:", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  // Handle snackbar close and dialog closing together
  const handleSnackbarClose = () => {
    setShowSuccess(false);
    onClose();
  };

  // Auto-close after showing success message
  useEffect(() => {
    if (showSuccess) {
      const timer = setTimeout(() => {
        handleSnackbarClose();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [showSuccess]);

  if (!formData) return null

  const renderPassengerTable = (passengers: Passenger[], showTicket = false) => (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-3 text-left text-sm font-bold border">Adı Soyadı</th>
            <th className="py-2 px-3 text-left text-sm font-bold border">Tip</th>
            <th className="py-2 px-3 text-left text-sm font-bold border">Doğum Tarihi</th>
            <th className="py-2 px-3 text-left text-sm font-bold border">Cinsiyet</th>
           
                <th className="py-2 px-3 text-left text-sm font-bold border">Bilet No</th>
                <th className="py-2 px-3 text-left text-sm font-bold border">Basit Ücret</th>
                <th className="py-2 px-3 text-left text-sm font-bold border">Vergi</th>
                <th className="py-2 px-3 text-left text-sm font-bold border">Hizmet Bedeli</th>
                <th className="py-2 px-3 text-left text-sm font-bold border">Gizli Hizmet Bedeli</th>
                <th className="py-2 px-3 text-left text-sm font-bold border">Toplam Ücret</th>
              
           
      
          </tr>
        </thead>
        <tbody>
          {passengers.map((passenger, index) => (
            <tr key={index} className="hover:bg-gray-50">
              <td className="py-2 px-3 text-sm border">{`${passenger.name || ''} ${passenger.surname || ''}`}</td>
              <td className="py-2 px-3 text-sm border">{passenger.paxType || '-'}</td>
              <td className="py-2 px-3 text-sm border">{passenger.birthdate ? moment(passenger.birthdate).format("DD-MM-YYYY") : '-'}</td>
              <td className="py-2 px-3 text-sm border">{passenger.gender || '-'}</td>
           
                  <td className="py-2 px-3 text-sm border">{passenger.ticketNumber || '-'}</td>
                  <td className="py-2 px-3 text-sm border">{passenger.baseFare ? currencyFormat(passenger.baseFare) : '-'}</td>
                  <td className="py-2 px-3 text-sm border">{passenger.tax ? currencyFormat(passenger.tax) : '-'}</td>
                  <td className="py-2 px-3 text-sm border">{passenger.serviceFee ? currencyFormat(passenger.serviceFee) : '-'}</td>
                  <td className="py-2 px-3 text-sm border">{passenger.hiddenServiceFee ? currencyFormat(passenger.hiddenServiceFee) : '-'}</td>
                  <td className="py-2 px-3 text-sm border">{passenger.baseFare   + passenger.tax + passenger.serviceFee  ? currencyFormat(passenger.baseFare + passenger.tax + passenger.serviceFee + passenger.hiddenServiceFee!) : '-'}</td>
         
           
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
        <DialogTitle className="flex justify-between items-center bg-gray-100 border-b">
          <div className="flex items-center">
            <span className="font-bold text-xl">Taslak Önizleme</span>
          </div>
        </DialogTitle>
        <DialogContent className="bg-gray-50 p-6">
          {/* Invoice Details Section */}
          <div className="mx-auto bg-white rounded-lg shadow-md p-6 mb-6 border">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div>
                <p className="text-sm font-semibold mb-1">Rezervasyon Referansı: <span className="font-normal">{formData.invoiceDetails?.productRefId || '-'}</span></p>
                <p className="text-sm font-semibold mb-1">Ödeme Durumu: <span className="font-normal">{formData.invoiceDetails?.invoiceStatus || '-'}</span></p>
                <p className="text-sm font-semibold mb-1">Jüpiter Rezervasyonu: <span className="font-normal">{formData.invoiceDetails?.isJupiterBooking ? "Evet" : "Hayır"}</span></p>
              </div>
              <div>
                <p className="text-sm font-semibold mb-1">Müşteri: <span className="font-normal">{formData.invoiceDetails?.customerName || '-'}</span></p>
                <p className="text-sm font-semibold mb-1">Müşteri ID: <span className="font-normal">{formData.invoiceDetails?.customerId || '-'}</span></p>
                <p className="text-sm font-semibold mb-1">Vade Tarihi: <span className="font-normal">{formData.invoiceDetails?.invoiceDueDate?.format("DD-MM-YYYY") || '-'}</span></p>
              </div>
            </div>
          </div>

          {/* Flight Bookings Section */}
          {formData.flights?.length > 0 && (
            <div className="mb-6">
              {formData.flights.map((flight: FlightType, index) => (
             <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
             <h3 className="text-lg font-bold mb-2 pb-2 border-b">
               Uçuş Rezervasyonu
             </h3>
             <p className="text-sm font-semibold mb-1">Havayolu: <span className="font-normal">{flight.airline}</span></p>
             <p className="text-sm font-semibold mb-1">Tedarikçi: <span className="font-normal">{formData.invoiceDetails?.productSupplierId}</span></p>

             <h4 className="text-md font-bold mt-4 mb-2">
               Uçuşlar
             </h4>
             <div className="overflow-x-auto">
               <table className="min-w-full border-collapse">
                 <thead className="bg-gray-100">
                   <tr>
                     <th className="py-2 px-3 text-left text-sm font-bold border">Uçuş Numarası</th>
                     <th className="py-2 px-3 text-left text-sm font-bold border">Kalkış</th>
                     <th className="py-2 px-3 text-left text-sm font-bold border">Varış</th>
                     <th className="py-2 px-3 text-left text-sm font-bold border">Sınıf</th>
                   </tr>
                 </thead>
                 <tbody>
                   {formData.flights.map((flight: FlightType) => (
                     <tr key={flight.id} className="hover:bg-gray-50">
                       <td className="py-2 px-3 text-sm border">{flight.flightNumber}</td>
                       <td className="py-2 px-3 text-sm border">{`${flight.from} (${moment(flight.departureDate).format("DD-MM-YYYY HH:mm")})`}</td>
                       <td className="py-2 px-3 text-sm border">{`${flight.to} (${moment(flight.arrivalDate).format("DD-MM-YYYY HH:mm")})`}</td>
                       <td className="py-2 px-3 text-sm border">{flight.class}</td>
                     </tr>
                   ))}
                 </tbody>
               </table>
             </div>

             {formData.allPassengers?.FLIGHT?.[flight.id]?.length > 0 && (
                    <>
                      <h4 className="text-md font-bold mt-4 mb-2">Misafirler</h4>
                      {renderPassengerTable(formData.allPassengers.FLIGHT[flight.id])}
                    </>
                  )}
           </div>
              ))}
            </div>
          )}

          {/* Hotel Bookings Section */}
          {formData.hotelBookings?.length > 0 && (
            <div className="mb-6">
              {formData.hotelBookings.map((hotel: HotelBooking, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                  <h3 className="text-lg font-bold mb-2 pb-2 border-b flex items-center">
                    <Hotel className="mr-2" />
                    Otel Rezervasyonu
                  </h3>
                  <p className="text-sm font-semibold mb-1">Otel Adı: <span className="font-normal">{hotel.hotelName || '-'}</span></p>
                  <p className="text-sm font-semibold mb-1">Giriş Tarihi: <span className="font-normal">{hotel.checkInDate?.format("DD-MM-YYYY") || '-'}</span></p>
                  <p className="text-sm font-semibold mb-1">Çıkış Tarihi: <span className="font-normal">{hotel.checkOutDate?.format("DD-MM-YYYY") || '-'}</span></p>
                  <p className="text-sm font-semibold mb-1">Oda Tipi: <span className="font-normal">{hotel.roomType || '-'}</span></p>
                  <p className="text-sm font-semibold mb-1">Pansiyon: <span className="font-normal">{hotel.board || '-'}</span></p>

                  {formData.allPassengers?.HOTEL?.[hotel.id]?.length > 0 && (
                    <>
                      <h4 className="text-md font-bold mt-4 mb-2">Misafirler</h4>
                      {renderPassengerTable(formData.allPassengers.HOTEL[hotel.id])}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Transfer Bookings Section */}
          {formData.transfers?.length > 0 && (
            <div className="mb-6">
              {formData.transfers.map((transfer: Transfer, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                  <h3 className="text-lg font-bold mb-2 pb-2 border-b flex items-center">
                    <TransferWithinAStation className="mr-2" />
                    Transfer Rezervasyonu
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-semibold mb-1">Kalkış Yeri: <span className="font-normal">{transfer.pickupLocation || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Varış Yeri: <span className="font-normal">{transfer.destinationLocation || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Araç Tipi: <span className="font-normal">{transfer.vehicleType || '-'}</span></p>
                    </div>
                    <div>
                      <p className="text-sm font-semibold mb-1">Kalkış Tarihi: <span className="font-normal">{transfer.pickupDate?.format("DD-MM-YYYY HH:mm") || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Dönüş Tarihi: <span className="font-normal">{transfer.returnDate?.format("DD-MM-YYYY HH:mm") || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Telefon: <span className="font-normal">{transfer.mobilePhone || '-'}</span></p>
                    </div>
                  </div>

                  <div className="mt-4">
                    <p className="text-sm font-semibold mb-1">Yolcu Sayısı: <span className="font-normal">
                      Yetişkin: {transfer.adtCount || 0}, 
                      Çocuk: {transfer.chdCount || 0}, 
                      Bebek: {transfer.infCount || 0}
                    </span></p>
                  </div>

                  {formData.allPassengers?.TRANSFER?.[transfer.id]?.length > 0 && (
                    <>
                      <h4 className="text-md font-bold mt-4 mb-2">Yolcular</h4>
                      {renderPassengerTable(formData.allPassengers.TRANSFER[transfer.id])}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

          {/* Car Rental Section */}
          {formData.carRentals?.length > 0 && (
            <div className="mb-6">
              {formData.carRentals.map((car: CarRental, index) => (
                <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4 border">
                  <h3 className="text-lg font-bold mb-2 pb-2 border-b flex items-center">
                    <DirectionsCar className="mr-2" />
                    Araç Kiralama
                  </h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-semibold mb-1">Araç Tipi: <span className="font-normal">{car.carType || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Alış Yeri: <span className="font-normal">{car.pickupLocation || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Teslim Yeri: <span className="font-normal">{car.returnLocation || '-'}</span></p>
                    </div>
                    <div>
                      <p className="text-sm font-semibold mb-1">Alış Tarihi: <span className="font-normal">{car.pickupDate?.format("DD-MM-YYYY") || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Teslim Tarihi: <span className="font-normal">{car.returnDate?.format("DD-MM-YYYY") || '-'}</span></p>
                      <p className="text-sm font-semibold mb-1">Günlük Ücret: <span className="font-normal">{car.dailyRentPrice ? currencyFormat(car.dailyRentPrice) : '-'}</span></p>
                    </div>
                  </div>

                  {formData.allPassengers?.RENTALCAR?.[car.id]?.length > 0 && (
                    <>
                      <h4 className="text-md font-bold mt-4 mb-2">Sürücüler</h4>
                      {renderPassengerTable(formData.allPassengers.RENTALCAR[car.id])}
                    </>
                  )}
                </div>
              ))}
            </div>
          )}

        </DialogContent>
        <DialogActions className="bg-gray-100 border-t p-4">
          <Button onClick={onClose} variant="contained" color="primary" disabled={isSubmitting}>Kapat</Button>
          <Button 
            className='border border-primary-6000 rounded-md font-semibold hover:bg-primary-50 transition-colors duration-200 text-primary'
            onClick={handleSubmit}
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            {isSubmitting ? 'Gönderiliyor...' : 'Gönder'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar is outside the Dialog */}
      <Snackbar 
        open={showSuccess} 
        autoHideDuration={2000} 
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="success" sx={{ width: '100%' }}>
          Fatura başarıyla kaydedildi!
        </Alert>
      </Snackbar>
    </>
  )
}

export default DraftPreviewDialog 