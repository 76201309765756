import Layout from "../layout";
import { AgencyList } from "./components/AgencyList";
import Breadcrumb from "components/ui/Breadcrumb/Breadcrumb";

const Agency: React.FC = () => {
    return (
        <Layout>
            <Breadcrumb 
                items={[
                    {label: "Dashboard", href: "/accounting"},
                    {label: "Acente Yönetimi", href: "/accounting/agency"},
                ]} 
            />
            <AgencyList />
        </Layout>
    );
};

export default Agency;
