import { Button, Checkbox, FormControlLabel, TextField, RadioGroup, Radio, FormControl, FormLabel } from '@mui/material';
import React, { useState } from 'react';

interface AgencyFiltersProps {
  onFilter: (filters: {
    isActive: boolean;
    hasSepa?: boolean;
    agencyNumber: string;
    agencyName: string;
  }) => void;
}

export const AgencyFilters: React.FC<AgencyFiltersProps> = ({ onFilter }) => {
  const [isActive, setIsActive] = useState(true);
  const [sepaFilter, setSepaFilter] = useState<'with' | 'without' | 'all'>('with');
  const [agencyNumber, setAgencyNumber] = useState('');
  const [agencyName, setAgencyName] = useState('');
  const [errors, setErrors] = useState<{ agencyNumber?: string; agencyName?: string }>({});

  const validateInputs = () => {
    const newErrors: typeof errors = {};
    
    if (agencyNumber) {
      if (isNaN(Number(agencyNumber)) || agencyNumber.length > 6) {
        newErrors.agencyNumber = "Geçerli bir numara giriniz (3-6 karakter arasında olmalıdır)";
      } else if (agencyNumber.length < 3) {
        newErrors.agencyNumber = "Numara en az 3 karakter olmalıdır";
      }
    }
    
    if (agencyName) {
      if (agencyName.length > 20) {
        newErrors.agencyName = "Acente adı 20 karakteri geçemez";
      } else if (agencyName.length < 3) {
        newErrors.agencyName = "Acente adı en az 3 karakter olmalıdır";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFilter = () => {
    if (!validateInputs()) return;
    
    // Create filters object with conditional hasSepa property
    const filters: {
      isActive: boolean;
      hasSepa?: boolean;
      agencyNumber: string;
      agencyName: string;
    } = {
      isActive,
      agencyNumber,
      agencyName,
    };
    
    // Only add hasSepa if not "all"
    if (sepaFilter === 'with') {
      filters.hasSepa = true;
    } else if (sepaFilter === 'without') {
      filters.hasSepa = false;
    }
    
    onFilter(filters);
  };

  return (
    <div className="flex flex-col space-y-6 p-6 bg-white rounded-lg border border-gray-200">
        <div className="flex flex-col space-y-4 md:col-span-2">

          <div className="flex items-start gap-8">

            <FormControl component="fieldset">
              <FormLabel component="legend">Aktiflik Durumu</FormLabel>
              <RadioGroup
                row
                value={isActive}
                onChange={(e) => setIsActive(e.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio />} label="Aktif Acenteler" />
                <FormControlLabel value="false" control={<Radio />} label="Pasif Acenteler" />
              </RadioGroup>
            </FormControl>
            
            <FormControl component="fieldset">
              <FormLabel component="legend">SEPA Durumu</FormLabel>
              <RadioGroup
                row
                value={sepaFilter}
                onChange={(e) => setSepaFilter(e.target.value as 'with' | 'without' | 'all')}
              >
                <FormControlLabel value="with" control={<Radio />} label="Sepa Olanlar" />
                <FormControlLabel value="without" control={<Radio />} label="Sepa Olmayanlar" />
                <FormControlLabel value="all" control={<Radio />} label="Tümü" />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
        <div className="flex flex-row w-full gap-4 items-center">   
        <TextField
          name="agencyNumber"
          label="Acente No"
          value={agencyNumber}
          variant="outlined"
          size="small"
          onChange={(e) => {
            setAgencyNumber(e.target.value);
            setErrors(prev => ({ ...prev, agencyNumber: '' }));
          }}
          error={Boolean(errors.agencyNumber)}
          helperText={errors.agencyNumber}
          sx={{ width: '50%' }}
        />
        <TextField
          name="agencyName"
          label="Acente Adı"
          value={agencyName}
          variant="outlined"
          size="small"
          onChange={(e) => {
            setAgencyName(e.target.value);
            setErrors(prev => ({ ...prev, agencyName: '' }));
          }}
          error={Boolean(errors.agencyName)}
          helperText={errors.agencyName}
          sx={{ width: '50%' }}
        />
         <div className="flex justify-end ">
        <Button
          variant="outlined"
          onClick={handleFilter}
          sx={{
            height: 40,
            px: 4,
            py: 2,
            textTransform: 'none'
          }}
        >
          Filtrele
        </Button>
      </div>
      </div>
       
    </div>
  );
}; 