import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormProvider } from "../contexts/FormContext";
import Layout from "../layout";
import InvoiceTable from "./components/InvoiceTable";
import { useReduxReducer } from "redux/store";
import Breadcrumb from "components/ui/Breadcrumb/Breadcrumb";

const Invoices: React.FC = () => {
  const {isAdmin} = useReduxReducer(state => state.general)
  return (
    <Layout>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
        <Breadcrumb 
        items={[
          {label: "Dashboard", href: "/accounting"},
          {label: "Kayıtlar", href: "/accounting/invoices"},
          ]} />
        <InvoiceTable />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};
export default Invoices;
