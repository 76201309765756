import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { FormProvider } from "../contexts/FormContext";
import Layout from "../layout";
import InvoiceTable from "./components/InvoiceTable";
import Breadcrumb from "components/ui/Breadcrumb/Breadcrumb";

const UnpaidInvoices: React.FC = () => {
  return (
    <Layout>
      <Breadcrumb 
        items={[
          {label: "Dashboard", href: "/accounting"},
          {label: "Faturalar", href: "/accounting/invoices"},
          {label: "Ödenmemiş Faturalar", href: "/accounting/invoices/unpaid"},
        ]} 
      />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <FormProvider>
          <h1 className="text-2xl font-bold mb-6">Ödeme Bekleyen Faturalar</h1>
          <InvoiceTable defaultPaid={false} />
        </FormProvider>
      </LocalizationProvider>
    </Layout>
  );
};

export default UnpaidInvoices; 