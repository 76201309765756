import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { FC, Fragment, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import NcImage from "components/ui/NcImage/NcImage";
import { useTranslation } from "react-i18next";

// <--- NavItemType --->
export interface MegamenuItem {
  id: string;
  image: string;
  title: string;
  items: NavItemType[];
}
export interface NavItemType {
  id: string;
  name: string;
  isNew?: boolean;
  isNewUrl?: boolean;
  href: string;
  targetBlank?: boolean;
  targetOldVersion?: boolean;
  children?: NavItemType[];
  megaMenu?: MegamenuItem[];
  type?: "dropdown" | "megaMenu" | "none";
  i18nextKey?: string | undefined;
}

export interface NavigationItemProps {
  menuItem: NavItemType;
}

const NavigationItem: FC<NavigationItemProps> = ({ menuItem }) => {
  const [menuCurrentHovers, setMenuCurrentHovers] = useState<string[]>([]);
  const location = useLocation();
  const { t } = useTranslation()

  // CLOSE ALL MENU OPENING WHEN CHANGE HISTORY
  useEffect(() => {
    setMenuCurrentHovers([]);
  }, [location.pathname]);

  const onMouseEnterMenu = (id: string) => {
    setMenuCurrentHovers((state) => [...state, id]);
  };

  const onMouseLeaveMenu = (id: string) => {
    setMenuCurrentHovers((state) => {
      return state.filter((item, index) => {
        return item !== id && index < state.indexOf(id);
      });
    });
  };

  // ===================== MENU MEGAMENU =====================
  const renderMegaMenu = (menu: NavItemType) => {
    const isHover = menuCurrentHovers.includes(menu.id);

    const isFull = menu.megaMenu && menu.megaMenu?.length > 3;
    const classPopover = isFull
      ? "menu-megamenu--large"
      : "menu-megamenu--small relative";
    const classPanel = isFull ? "left-0" : "-translate-x-1/2 left-1/2";

    return (
      <Popover
        as="li"
        className={`menu-item menu-megamenu ${classPopover}`}
        onMouseEnter={() => onMouseEnterMenu(menu.id)}
        onMouseLeave={() => onMouseLeaveMenu(menu.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {RenderMainItem(menu)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-2"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-2"
            >
              <Popover.Panel
                static
                className={`nc-will-change-transform sub-menu absolute z-10 w-screen max-w-sm transform px-4 pt-3 sm:px-0 lg:max-w-max ${classPanel}`}
              >
                <div className="overflow-hidden rounded-xl text-sm shadow-xl ring-1 ring-black ring-opacity-5 dark:ring-white dark:ring-opacity-10">
                  <div
                    className={`relative grid gap-2 bg-white px-4 py-6 dark:bg-neutral-800 grid-cols-${menu.megaMenu?.length}`}
                  >
                    {menu.megaMenu?.map((item) => (
                      <div key={item.id} className="p-2">
                        <div className="px-2">
                          <NcImage
                            containerClassName="w-36 h-24 rounded-lg overflow-hidden relative flex shadow-sm"
                            src={item.image}
                          />
                        </div>
                        <p className="my-2 py-1 px-2 font-medium text-neutral-900 dark:text-neutral-100">
                          {item.title}
                        </p>
                        <ul className="grid space-y-1">
                          {item.items.map(renderMegaMenuNavlink)}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };
  
  const renderMegaMenuNavlink = (item: NavItemType) => {
    return (
      <li key={item.id}>
        <NavLink
          end
          target={item.targetBlank ? "_blank" : undefined}
          rel="noopener noreferrer"
          className={({ isActive }) =>
            "inline-flex items-center rounded-lg py-1.5 px-3 font-normal text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:hover:text-neutral-100 transition-all duration-150" +
            (isActive
              ? " font-medium text-neutral-900 bg-neutral-100 dark:bg-neutral-700 dark:!text-neutral-100"
              : "")
          }
          to={{
            pathname: item.href || undefined,
          }}
        >
          {item.name}
        </NavLink>
      </li>
    );
  };

  // ===================== MENU DROPDOW =====================
  const renderDropdownMenu = (menuDropdown: NavItemType) => {
    const isHover = menuCurrentHovers.includes(menuDropdown.id);
    return (
      <Popover
        as="li"
        className={`menu-item menu-dropdown relative ${
          menuDropdown.isNew ? "menuIsNew_lv1" : ""
        }`}
        onMouseEnter={() => onMouseEnterMenu(menuDropdown.id)}
        onMouseLeave={() => onMouseLeaveMenu(menuDropdown.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {RenderMainItem(menuDropdown)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-2"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-2"
            >
              <Popover.Panel
                static
                className="sub-menu nc-will-change-transform absolute left-0 z-10 w-60 transform pt-3"
              >
                <ul className="relative grid space-y-0.5 rounded-xl bg-white py-4 text-sm shadow-xl ring-1 ring-black ring-opacity-5 dark:bg-neutral-800 dark:ring-white dark:ring-opacity-10">
                  {menuDropdown.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li
                          key={i.id}
                          className={`px-2 ${i.isNew ? "menuIsNew" : ""}`}
                        >
                          {i.targetOldVersion ? renderDropdownMenuAElement(i) : renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlinkHasChild = (item: NavItemType) => {
    const isHover = menuCurrentHovers.includes(item.id);
    return (
      <Popover
        as="li"
        key={item.id}
        className="menu-item menu-dropdown relative px-2"
        onMouseEnter={() => onMouseEnterMenu(item.id)}
        onMouseLeave={() => onMouseLeaveMenu(item.id)}
      >
        {() => (
          <>
            <Popover.Button as={Fragment}>
              {renderDropdownMenuNavlink(item)}
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isHover}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-2"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-2"
            >
              <Popover.Panel
                static
                className="sub-menu absolute left-full top-0 z-10 w-60 pl-2"
              >
                <ul className="relative grid space-y-0.5 rounded-xl bg-white py-4 text-sm shadow-xl ring-1 ring-black ring-opacity-5 dark:bg-neutral-800 dark:ring-white dark:ring-opacity-10">
                  {item.children?.map((i) => {
                    if (i.type) {
                      return renderDropdownMenuNavlinkHasChild(i);
                    } else {
                      return (
                        <li key={i.id} className="px-2">
                          {item.targetOldVersion ? renderDropdownMenuAElement(i) : renderDropdownMenuNavlink(i)}
                        </li>
                      );
                    }
                  })}
                </ul>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    );
  };

  const renderDropdownMenuNavlink = (item: NavItemType) => {
    return (
      <NavLink
        end
        target={item.targetBlank ? "_blank" : undefined}
        rel="noopener noreferrer"
        className={({ isActive }) =>
          "flex items-center rounded-lg py-2.5 px-4 font-normal text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:hover:text-neutral-100 transition-all duration-150" +
          (isActive ? " !font-medium bg-neutral-100 text-neutral-900 dark:bg-neutral-700 dark:!text-neutral-100" : "")
        }
        to={{
          pathname: item.href || undefined
        }}
      >
        {item.i18nextKey && t(item.i18nextKey) ? t(item.i18nextKey) : item.name}
        {item.type && (
          <ChevronDownIcon
            className="ml-2 h-4 w-4 text-neutral-500 rotate-[-90deg]"
            aria-hidden="true"
          />
        )}
      </NavLink>
    );
  };

  const renderDropdownMenuAElement = (item: NavItemType, isActive: boolean = false) => {
    return (
      <a
        target={item.targetBlank ? "_blank" : undefined}
        rel="noopener noreferrer"
        className={"flex items-center rounded-lg py-2.5 px-4 font-normal text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:hover:text-neutral-100 transition-all duration-150" +
            (isActive ? " !font-medium bg-neutral-100 text-neutral-900 dark:bg-neutral-700 dark:!text-neutral-100" : "")}
        href={item.href}
      >
        {item.i18nextKey && t(item.i18nextKey) ? t(item.i18nextKey) : item.name}
        {item.type && (
          <ChevronDownIcon
            className="ml-2 h-4 w-4 text-neutral-500 rotate-[-90deg]"
            aria-hidden="true"
          />
        )}
      </a>
    );
  };

  // ===================== MENU MAIN MENU =====================
  const RenderMainItem = (item: NavItemType) => {
    return (
      <NavLink
        end
        target={item.targetBlank ? "_blank" : undefined}
        rel="noopener noreferrer"
        className={({ isActive }) =>
          "inline-flex items-center rounded-lg py-2 px-3 font-normal text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:hover:text-neutral-200 xl:px-3 text-[15px] transition-all duration-150" +
          (isActive
            ? " bg-neutral-100 !font-medium !text-neutral-900 dark:bg-neutral-800 dark:!text-neutral-100 border-b-2 border-primary-600"
            : "")
        }
        to={{
          pathname: item.href || undefined,
        }}
      >
        {item.i18nextKey && t(item.i18nextKey) ? t(item.i18nextKey) : item.name}
        {item.type && (
          <ChevronDownIcon
            className="ml-1 h-4 w-4 text-neutral-500 transition-transform duration-150 group-hover:rotate-180"
            aria-hidden="true"
          />
        )}
      </NavLink>
    );
  };

  switch (menuItem.type) {
    case "megaMenu":
      return renderMegaMenu(menuItem);
    case "dropdown":
      return renderDropdownMenu(menuItem);
    default:
      return <li className="menu-item">{RenderMainItem(menuItem)}</li>;
  }
};

export default NavigationItem;
