import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Agency } from '../types/agency';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import currencyFormat from 'utils/currencyFormat';
import moment from 'moment';
import InvoiceTable from '../Invoices/components/InvoiceTable';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { FormProvider } from '../contexts/FormContext';
import Layout from '../layout';
import { PaginatedResponse, useGetData } from '../hooks/useGetData';
import { getAgencySearchUrl } from 'api/helpers/api-constants';

const AgencyDetail: React.FC = () => {
  const { agencyNumber} = useParams<{
     agencyNumber: string, 
     }>();
 
     const { 
      data, 
      isLoading, 
      isError, 
      refetch 
    } = useGetData<PaginatedResponse<Agency>, any>(
      getAgencySearchUrl,
      {
        pageSize: 1,
        activePage: 0,
        agencyNumber: agencyNumber
      },
      { 
        result: [], 
        pageSize: 0, 
        activePage: 0, 
        totalCount: 0, 
        totalPages: 0 
      }
    ); 

    const agency = data?.result[0];

    return (
    <Layout>
      <div className="p-6 space-y-4">
        <Breadcrumb 
          items={[
            { label: "Acente Listesi", href: "/accounting/agency" },
            { label: `Acente Detayı - ${agencyNumber}`, href: `/accounting/agency/${agencyNumber}` }
          ]} 
        />
        
        {/* Agency Details Card */}
        {isLoading && <LoadingComponent isLoading={isLoading} service='all' />}
       {agency && (
         <div className="bg-gradient-to-br from-white to-gray-50 p-8 rounded-lg border border-gray-100">
           <div className="flex justify-between items-center mb-6">
             <h2 className="text-2xl font-bold text-gray-800">{agency.name} - {agency.number}</h2>
             <span className={`px-4 py-1 rounded-full text-sm font-medium ${
               agency.active 
                 ? 'bg-green-100 text-green-800' 
                 : 'bg-red-100 text-red-800'
             }`}>
               {agency.active ? 'Active' : 'Inactive'}
             </span>
           </div>
           
           <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
             <div className="space-y-4">
               <div className="bg-gray-50 p-2 rounded-lg border border-gray-100">
                 <p className="text-sm text-gray-500 font-medium mb-1">Bakiye</p>
                 <p className={`text-xl font-bold ${Number(agency.balance) < 0 ? 'text-red-600' : 'text-gray-800'}`}>
                   {currencyFormat(Number(agency.balance))}
                 </p>
               </div>
               
               <div className="bg-gray-50 p-2 rounded-lg border border-gray-100">
                 <p className="text-sm text-gray-500 font-medium mb-1">Şehir</p>
                 <p className="text-lg font-semibold text-gray-800">{agency.cityName}</p>
               </div>
             </div>
             
             <div className="space-y-4">
               <div className="bg-gray-50 p-2 rounded-lg border border-gray-100">
                 <p className="text-sm text-gray-500 font-medium mb-1">SEPA Limiti</p>
                 <p className="text-xl font-bold text-gray-800">{currencyFormat(Number(agency.sepaLimit))}</p>
               </div>
               
              <div className="bg-gray-50 p-2 rounded-lg border border-gray-100">
                 <p className="text-sm text-gray-500 font-medium mb-1">Son İşlem Tarihi</p>
                 <p className="text-lg font-semibold text-gray-800">
                   {agency.lastPurchaseTransactionDate ? moment(agency.lastPurchaseTransactionDate).format('DD.MM.YYYY') : '-'}
                 </p>
               </div>
             </div>
           </div>
         </div>
       )}

        {agencyNumber && <InvoiceTable agencyNumber={Number(agencyNumber)} />}
      </div>
    </Layout>
  );
};

export default AgencyDetail; 