import React from 'react';
import { useFormContext } from '../../contexts/FormContext';
import { DateTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Button, TextField, IconButton } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';

interface Transfer {
  id: number;
  pickupLocation: string;
  destinationLocation: string;
  pickupDate: moment.Moment;
  returnDate: moment.Moment;
  vehicleType: string;
  flightNumber: string;
  flightDeparturePort: string;
  flightDate: string;
  adtCount: number;
  chdCount: number;
  infCount: number;
  mobilePhone: string;
}

const TransferForm: React.FC = () => {
  const { formData, updateFormData } = useFormContext();

  const addTransfer = () => {
    const newTransferId = formData.transfers?.length > 0 
      ? Math.max(...formData.transfers.map(t => t.id)) + 1 
      : 1;
    
    const newTransfer: Transfer = {
      id: newTransferId,
      pickupLocation: '',
      destinationLocation: '',
      pickupDate: moment(),
      returnDate: moment(),
      vehicleType: '',
      flightNumber: '',
      flightDeparturePort: '',
      flightDate: '',
      adtCount: 0,
      chdCount: 0,
      infCount: 0,
      mobilePhone: '',
    };

    updateFormData({
      transfers: [...(formData.transfers || []), newTransfer],
    });
  };

  const removeTransfer = (id: number) => {
    updateFormData({
      transfers: (formData.transfers || []).filter(t => t.id !== id)
    });
  };

  const updateTransfer = (index: number, field: keyof Transfer, value: any) => {
    const updatedTransfers = [...(formData.transfers || [])];
    updatedTransfers[index] = {
      ...updatedTransfers[index],
      [field]: value,
    };
    updateFormData({ transfers: updatedTransfers });
  };

  return (
    <div className="space-y-6">
      {formData.transfers?.map((transfer: Transfer, index: number) => (
        <div key={transfer.id} className="p-4 border bg-light rounded-lg space-y-4 relative">
          <span className='flex justify-between items-center py-4'>
            <h2 className="font-semibold text-xl text-gray-800">
              Transfer {index + 1}
            </h2>
            {(formData.transfers?.length || 0) > 1 && (
              <IconButton 
                onClick={() => removeTransfer(transfer.id)}
                className=""
                color="error"
                size="small"
              >
                <Delete />
              </IconButton>
            )}
          </span>

          <div className="grid grid-cols-2 gap-4">
            <TextField
              label="Alınacak Konum"
              value={transfer.pickupLocation}
              onChange={(e) => updateTransfer(index, 'pickupLocation', e.target.value)}
            />
            <TextField
              label="Varış Konumu"
              value={transfer.destinationLocation}
              onChange={(e) => updateTransfer(index, 'destinationLocation', e.target.value)}
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <DateTimePicker
              label="Alım Tarihi"
              value={transfer.pickupDate}
              onChange={(date) => updateTransfer(index, 'pickupDate', date)}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
            />
            <DateTimePicker
              label="Dönüş Tarihi"
              value={transfer.returnDate}
              onChange={(date) => updateTransfer(index, 'returnDate', date)}
              format="DD/MM/YYYY HH:mm"
              ampm={false}
            />
          </div>

          <div className="grid grid-cols-3 gap-4">
            <TextField
              label="Araç Tipi"
              value={transfer.vehicleType}
              onChange={(e) => updateTransfer(index, 'vehicleType', e.target.value)}
            />
            <TextField
              label="Uçuş Numarası"
              value={transfer.flightNumber}
              onChange={(e) => updateTransfer(index, 'flightNumber', e.target.value)}
            />
            <TextField
              label="Uçuş Kalkış Limanı"
              value={transfer.flightDeparturePort}
              onChange={(e) => updateTransfer(index, 'flightDeparturePort', e.target.value)}
            />
          </div>

          <div className="grid grid-cols-4 gap-4">
            <TextField
              type="number"
              label="Yetişkin Sayısı"
              value={transfer.adtCount}
              onChange={(e) => updateTransfer(index, 'adtCount', parseInt(e.target.value))}
            />
            <TextField
              type="number"
              label="Çocuk Sayısı"
              value={transfer.chdCount}
              onChange={(e) => updateTransfer(index, 'chdCount', parseInt(e.target.value))}
            />
            <TextField
              type="number"
              label="Bebek Sayısı"
              value={transfer.infCount}
              onChange={(e) => updateTransfer(index, 'infCount', parseInt(e.target.value))}
            />
            <TextField
              label="Cep Telefonu"
              value={transfer.mobilePhone}
              onChange={(e) => updateTransfer(index, 'mobilePhone', e.target.value)}
            />
          </div>
        </div>
      ))}
      
      <div className='flex justify-start items-center'>
        <Button 
          variant="outlined" 
          color="primary" 
          onClick={addTransfer}
          startIcon={<Add />}
          className="mt-4"
        >
          Transfer Ekle
        </Button>
      </div>
    </div>
  );
};

export default TransferForm; 