import React, { useEffect, useState } from 'react';
import { ColumnDef, SortingState, PaginationState } from '@tanstack/react-table';
import { TableComponent } from 'components/ui/TableComponent/TableComponent';
import Breadcrumb from 'components/ui/Breadcrumb/Breadcrumb';
import { useGetData, PaginatedResponse } from 'pages/Accounting/hooks/useGetData';
import { Agency } from 'pages/Accounting/types/agency';
import moment from 'moment';
import currencyFormat from 'utils/currencyFormat';
import LoadingComponent from 'components/ui/LoadingComponent/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { getAgencySalesUrl } from 'api/helpers/api-constants';
import { ReportFilterDates } from './components/ReportFilterDates';
import { Moment } from 'moment';
const columns: ColumnDef<Agency>[] = [
  {
    accessorKey: 'number',
    header: 'Acente No',
  },
  {
    accessorKey: 'name',
    header: 'Acente Adı',
  },
  {
    accessorKey: 'flightSales',
    header: 'Uçuş Satışları',
    cell: ({ getValue }) => currencyFormat(getValue() as number || 0)
  },
  {
    accessorKey: 'hotelSales',
    header: 'Otel Satışları',
    cell: ({ getValue }) => getValue() ? currencyFormat(getValue() as number) : '-'
  },
  {
    accessorKey: 'carRentalSales',
    header: 'Araç Kiralama',
    cell: ({ getValue }) => currencyFormat(getValue() as number || 0)
  },
  {
    accessorKey: 'transferSales',
    header: 'Transfer Satışları',
    cell: ({ getValue }) => currencyFormat(getValue() as number || 0)
  },
  {
    accessorKey: 'transactionCount',
    header: 'İşlem Sayısı',
  },
  {
    accessorKey: 'totalSales',
    header: 'Toplam Satış',
    cell: ({ getValue }) => (
      <span className="font-medium text-blue-600">
        {currencyFormat(getValue() as number)}
      </span>
    )
  }
];

interface ReportSalesProps {
}

export const ReportSales: React.FC<ReportSalesProps> = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 10 });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [filters, setFilters] = useState({
    fromDate:   moment().subtract(1, 'month').startOf('month') as Moment | null,
    toDate: moment().add(1, 'month').endOf('month') as Moment | null,
    agencyName: '',
    agencyNumber: '',
  });

  const { 
    data, 
    isLoading, 
    isError, 
    refetch 
  } = useGetData<PaginatedResponse<Agency>, any>(
    getAgencySalesUrl,
    {
      agencyName: filters.agencyName,
      fromDate: moment(filters.fromDate).format('YYYY-MM-DD'),
      toDate: moment(filters.toDate).format('YYYY-MM-DD'),
      agencyNumber: filters.agencyNumber,
      pageSize: pagination.pageSize,
      activePage: pagination.pageIndex,
    },
    { 
      result: [], 
      pageSize: 0, 
      activePage: 0, 
      totalCount: 0, 
      totalPages: 0 
    }
  );

  const handleRowClick = (agency: Agency) => {
    navigate(`/accounting/agency/${agency.number}`);
    return;
  };

  const handleFilter = (newFilters: {
    agencyName: string;
    agencyNumber: string;
    fromDate: Moment | null;
    toDate: Moment | null;
  }) => {
    setFilters(newFilters);
    setPagination({ ...pagination, pageIndex: 0 });
  };

  if (isError) return <div>Error loading agencies</div>;

  return (
    <div className="p-6 space-y-4">      
      <ReportFilterDates onFilter={handleFilter} />

      <TableComponent
        data={data?.result || []}
        columns={columns}
        manualPagination={true}
        manualSorting={false}
        sorting={sorting}
        onSortingChange={setSorting}
        pagination={pagination}
        onPaginationChange={setPagination}
        onRowClick={handleRowClick}
        totalRecords={data?.totalCount}
        sumColumnId="totalSales"
      />
      <LoadingComponent isLoading={isLoading} service="general" />

    </div>
  );
};
