import React from 'react';
import Layout from "../layout";
import { ReportTable } from './components/ReportTable';
import Breadcrumb from "components/ui/Breadcrumb/Breadcrumb";
import { ReportDebtors } from './ReportDebtors';
import { ReportSales } from './ReportSales';
import { ReportSuplier } from './ReportSuplier';
export const DebtorsReport = () => (
  <Layout>
<ReportDebtors />  </Layout>
);

export const CreditorsReport = () => (
  <Layout>
    <ReportSales />
  </Layout>
);

export const SalesByAgencyReport = () => (
  <Layout>
    <ReportSales />
  </Layout>
);

export const SalesByProductReport = () => (
  <Layout>
       <ReportSuplier />

  </Layout>
);

export const PurchasesBySupplierReport = () => (
  <Layout>
   <ReportSuplier />
   </Layout>
);

const Reports: React.FC = () => {
  return (
    <Layout>
      <Breadcrumb 
        items={[
          {label: "Dashboard", href: "/accounting"},
          {label: "Raporlar", href: "/accounting/reports"},
        ]} 
      />
      <h1 className="text-3xl font-bold mb-4">Raporlar</h1>
      <div className="space-y-8">
        <ReportDebtors />
        <ReportSales />
        <ReportSuplier />
      </div>
    </Layout>
  );
};

export default Reports;
