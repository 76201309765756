import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import moment, { Moment } from 'moment';
import { DatePickermui } from '../../../../components/ui/DatePicker/DatePickermui';

interface ReportFilterDatesProps {
  initialFilters?: {
    agencyName: string;
    agencyNumber: string;
    fromDate: Moment | null;
    toDate: Moment | null;
  };
  onFilter: (filters: {
    agencyName: string;
    fromDate: Moment | null;
    toDate: Moment | null;
    agencyNumber: string;
  }) => void;
}

export const ReportFilterDates: React.FC<ReportFilterDatesProps> = ({ onFilter, initialFilters }) => {
  const [agencyName, setAgencyName] = useState(initialFilters?.agencyName || '');
  const [agencyNumber, setAgencyNumber] = useState(initialFilters?.agencyNumber || '');
  const [errors, setErrors] = useState<{ agencyName?: string }>({});
  const [fromDate, setFromDate] = useState<Moment | null>(
    initialFilters?.fromDate || moment().subtract(1, 'month').startOf('month')
  );
  const [toDate, setToDate] = useState<Moment | null>(
    initialFilters?.toDate || moment().add(1, 'month').endOf('month')
  );
  
  console.log(initialFilters);
  
  const validateInputs = () => {
    const newErrors: typeof errors = {};
    
    if (agencyName) {
      if (agencyName.length > 20) {
        newErrors.agencyName = "Acente adı 20 karakteri geçemez";
      } else if (agencyName.length < 3) {
        newErrors.agencyName = "Acente adı en az 3 karakter olmalıdır";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFromDateChange = (date: Moment | null) => {
    setFromDate(date);
  };

  const handleToDateChange = (date: Moment | null) => {
    setToDate(date);
  };

  const handleFilter = () => {
    if (!validateInputs()) return;
    onFilter({  
      agencyName,
      agencyNumber,
      fromDate,
      toDate,
    });
  };

  return (
    <div className="flex flex-col space-y-6 p-6 bg-white rounded-lg border border-gray-200">
      <div className="flex flex-row gap-4">
        <TextField
          name="agencyName"
          label="Acente Adı"
          value={agencyName}
          variant="outlined"
          size="small"
          onChange={(e) => {
            setAgencyName(e.target.value);
            setErrors(prev => ({ ...prev, agencyName: '' }));
          }}
          error={Boolean(errors.agencyName)}
          helperText={errors.agencyName}
          sx={{ width: '20%' }}
        />
        <TextField
          name="agencyNumber"
          label="Acente Numarası"
          value={agencyNumber}
          variant="outlined"
          size="small"
          onChange={(e) => setAgencyNumber(e.target.value)}
          sx={{ width: '20%' }}
        />
        
        <div className="flex items-center gap-2" style={{ width: '40%' }}>
          <DatePickermui
            date={fromDate as moment.Moment}
            handleDateChange={handleFromDateChange}
            className="w-full"
          />
          <span className="mx-2">-</span>
          <DatePickermui
            date={toDate as moment.Moment}
            handleDateChange={handleToDateChange}
            className="w-full"
          />
        </div>
                 
        <Button 
          variant="outlined" 
          onClick={handleFilter}
          sx={{
            height: 40,
            px: 4,
            py: 2,
            textTransform: 'none'
          }}
        >
          Filtrele
        </Button>
      </div>
    </div>
  );
}; 